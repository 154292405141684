import Axios from "axios";
import Swal from "sweetalert2";
import appConfig from "../../config/appConfig.json";
import * as callActions from "../actions/callActions";

export const RETRIEVE_MATERIAL_TYPES = "RETRIEVE_MATERIAL_TYPES";
export const SEARCH_FRIEND = "SEARCH_FRIEND";
export const INVITE_USER = "INVITE_USER";
export const GET_SENT_INVITATIONS = "GET_SENT_INVITATIONS";
export const RETRIEVE_DEVICE_NAME = "RETRIEVE_DEVICE_NAME";
export const RETRIEVE_DEVICE_DATA = "RETRIEVE_DEVICE_DATA";
export const CALL_USER = "CALL_USER";
export const GET_INVITATIONS_LIST = "GET_INVITATIONS_LIST";
export const GET_USER_EVENTS = "GET_USER_EVENTS";
export const GET_USER_END_EVENTS = "GET_USER_END_EVENTS";
export const GET_DEVICE_RECORDING = "GET_DEVICE_RECORDING";
export const GET_MY_FRIENDS_LIST = "GET_MY_FRIENDS_LIST";


export const retrieveDeviceName = (mac_address) => async (dispatch) => {
  try {
    const res = await Axios.post(
      appConfig.baseUrl + "/device/getDeviceByMacAddress",
      {
        macAddress: mac_address,
      }
    );
    dispatch({
      type: RETRIEVE_DEVICE_NAME,
      payload: res.data.device.display_name,
    });
   
  } catch (err) {
    console.log(err);
  }
};

export const retrieveDeviceData = (mac_address) => async (dispatch) => {
  try {
    const res = await Axios.post(
      appConfig.baseUrl + "/device/getDeviceByMacAddress",
      {
        macAddress: mac_address,
      }
    );
    console.log("deviceDetail", res.data);
    dispatch({
      type: RETRIEVE_DEVICE_DATA,
      payload: res.data.device,
    });
  } catch (err) {
    console.log(err);
  }
};

export const retrieveMaterialTypes = () => async (dispatch) => {
  try {
    const res = await Axios.get(
      appConfig.baseUrl + "/device/getAllMaterialTypes/"
    );

    dispatch({
      type: RETRIEVE_MATERIAL_TYPES,
      payload: res.data.materialTypes,
    });
  } catch (err) {
    console.log(err);
  }
};
export const CallUser =
  (calleeId, callerId, roomNumber) => async (dispatch) => {
    try {
      const res = await Axios.post(
        appConfig.baseUrl + "/user/api/callUserFromUser",
        {
          roomNumber: roomNumber,
          calleeId: calleeId,
          callerId: callerId,
        }
      );
      dispatch({
        type: callActions.CALL_SET_ROOM,
        payload: roomNumber,
      });
      dispatch({
        type: CALL_USER,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };


  export const getSentInvitations = () => async (dispatch) => {

    try {
      const res = await Axios.post(
        appConfig.baseUrl + "/user/api/getSentInvitation",
        {
          aUserId: localStorage.getItem("id"),
        }
      );
      dispatch({
        type: GET_SENT_INVITATIONS,
        payload: res.data,
      });
     
    } catch (err) {
      console.log(err);
    }
 
  };

export const getInvitationsList = () => (dispatch) => {
  try {
    let userId = localStorage.getItem("id");
    Axios.post(appConfig.baseUrl + "/user/api/getinvitationlists", {
      idUser: userId,
    })
      .then((response) => {
        console.log("invitationFriendListRedux",response.data)
        dispatch({
          type: GET_INVITATIONS_LIST,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (err) {
    console.log(err);
  }
};
export const getMyFriendsList = () => (dispatch) => {
  try {
    let userId = localStorage.getItem("id");
    Axios.post(appConfig.baseUrl + "/user/api/getmyfriendlists", {
      idUser: userId,
    })
      .then((response) => {
        console.log("myFriendListRedux",response.data)
        dispatch({
          type: GET_MY_FRIENDS_LIST,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (err) {
    console.log(err);
  }
};
export const searchFriend = (friend) => async (dispatch) => {
  try {
    let userId = localStorage.getItem("id");
    const res = await Axios.post(
      appConfig.baseUrl + "/user/api/searchFriends",
      {
        friend: friend,
        idUser: userId,
      }
    );

    console.log("res invite", res.data.friends);
    dispatch({
      type: SEARCH_FRIEND,
      payload: res.data.friends,
    });
  } catch (err) {
    console.log(err);
  }
}

export const inviteUser = (receiverEmail) => async (dispatch) => {
  
  //const { t } = useTranslation();
  Swal.fire({
    title: "Are you sure?",
    text: "You want to send the invitation !",
    icon: "question",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes !",
  }).then((result) => {
    if (result.isConfirmed) {
      let res = Axios.post(
        appConfig.baseUrl + "/user/api/inviteFriendWithEmail",
        {
          emailUser: localStorage.getItem("email"),
          emailFriend: receiverEmail,
        }
      );
      console.log("resInvited", res.data);
      dispatch({
        type: INVITE_USER,
        payload: res.data,
      });
   
    Swal.fire("success", "Your invitation has been sent.", "success").then(
    
       () =>{// window.location.reload(false)
          getSentInvitations()
        } 
     
      ); 
    }
  });
};



export const getUserStopCallEvent = (callId) => async (dispatch) => {
  try {
 
 
    Axios.post(appConfig.baseUrl + "/user/api/getUserEndCallEventsByCallId", {
      callId: callId,
    })
      .then((response) => {
        if(response.data.success){
        console.log("getUserStopCallEvent",response)
        dispatch({
          type: GET_USER_END_EVENTS,
          payload: response.data.events,
        });
      }
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (err) {
    console.log(err);
  }
};

export const getUserCallEvents = () => async (dispatch) => {
  try {
    let userId = localStorage.getItem("id");
    console.log("userId",userId)
    Axios.post(appConfig.baseUrl + "/user/api/getUserCallEvents", {
      userId: userId,
    })
      .then((response) => {
        if(response.data.success){
          
        dispatch({
          type: GET_USER_EVENTS,
          payload: response.data.events,
        });
      }
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (err) {
    console.log(err);
  }
};


export const getUserEndCallEvents = () => async (dispatch) => {
  try {
    let userId = localStorage.getItem("id");
    Axios.post(appConfig.baseUrl + "/user/api/getUserCallEvents", {
      userId: userId,
    })
      .then((response) => {
        if(response.data.success){
        console.log("getUserCallEventsRedux",response)
        dispatch({
          type: GET_USER_EVENTS,
          payload: response.data.events,
        });
      }
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (err) {
    console.log(err);
  }
};


export const getDeviceRecordings = (mac_address) => async (dispatch) => {
  try {
    const res = await Axios.post(
      appConfig.baseUrl + "/device/getRecordingByMacAddress",
      {
        macAddress: mac_address,
      }
    );
    console.log('macAddress: ' + mac_address);
    console.log('getDeviceRecording',res.data.Recording)
    dispatch({
      type: GET_DEVICE_RECORDING,
      payload: res.data.Recording,
    });
  } catch (err) {
    console.log(err);
  }
};