import React, { useEffect, useState, useRef } from "react";

import io from "socket.io-client";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import NativeSelect from "@mui/material/NativeSelect";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/material/Grid";
import Navbar from "./components/Navbar/Navbar";
import NavItem from "./components/Navbar/NavItem";
import DropdownMenu from "./components/Navbar/DropDownMenu";
import DropDownAddFriendMenu from "./components/Navbar/DropDownAddFriendMenu";
import DropDownAddDeviceMenu from "./components/Navbar/DropDownAddDeviceMenu";
import { Container } from "@mui/material";
import { GoDeviceCameraVideo } from "react-icons/go";
import { RiAddFill } from "react-icons/ri";
import DropdownNotificationMenu from "./components/Navbar/DropDownNotificationMenu";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles";
import "./CallView.css";
import "./components/Navbar/Navbar.css";
import Axios from "axios";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import MobileConversationButtons from "../CallView/components/ConversationButtons/MobileConversationButtons";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import { useParams } from "react-router-dom";


const styles = {
  videoElement: {
   
    width: "100%",
    height: "100%",
  },
};

//const socket = io("http://localhost:3200");
const MobileCallView = ({socket}) => {
  const Width = useRef(null);

  const remote = useRef();
  const useStyles = makeStyles({
    textInputWrapper: {
      marginTop: "1em",
      marginBottom: "1em",
    },
    input: {
      "&::placeholder": {
        fontWeight: "bold",
        fontStyle: "italic",
      },
    },
    root: {
      "&:not(.Mui-disabled):hover::after": {
        borderColor: "#1D1D1D",
      },
      "&:not(.Mui-disabled):hover::before": {
        borderColor: "#1D1D1D",
      },
      "&:not(.Mui-disabled):focus::after": {
        borderColor: "#1D1D1D",
      },
      "&:not(.Mui-disabled):focus::before": {
        borderColor: "#1D1D1D",
      },
    },
  });

  const classes = useStyles();
  const [deviceData, setDeviceData] = useState([]);
  const [mac_address, setMac_address] = useState("");
  const [display_name, setDisplayName] = useState("");
  const [materialTypes, setMaterialTypes] = useState([]);
  const [deviceType, setDeviceType] = useState("");
  const [waiting,setWaiting]=useState(true);
  const [connecting,setConnecting]=useState(false);
  const [localCameraStream,setLocalCameraStream]=useState();
  const [cameraEnabled,setCameraEnabled]=useState(true);
  const onChangeName = (event) => {
    event.preventDefault();
    setDisplayName(event.target.value);
  };
  const onChange = (event) => {
    event.preventDefault();
    setMac_address(event.target.value);
  };
  const onSelect = (event) => {
    event.preventDefault();
    setDeviceType(event.target.value);
  };

  const connectWithWebSocket = () => {
    socket.on("connection", () => {
      console.log("succesfully connected with socket server");
      console.log(socket.id);
    });
  };

  var iceServers = {
    iceServers: [
      {
        url: "turn:54.37.68.97:3478",
        username: "smaart",
        credential: "smaart",
      },
      { urls: "stun:stun.services.mozilla.com" },
      { urls: "stun:stun.l.google.com:19302" },
    ],
  };
  var streamConstraints = { audio: true, video: true };

  var isCaller;
  var rtcPeerConnection;
  let { roomId } = useParams();
  let count;
  let nCam;
  nCam = document.querySelectorAll(".intent").length;
  const handleHangUpButtonPressed = () => {
  
    
   localCameraStream.getTracks().forEach(t => t.stop());
   console.log("rtcPeerConnection",rtcPeerConnection);
   socket.disconnect(true);
   window.location.reload();
   };
 

  useEffect(() => {
    connectWithWebSocket();
    return () => socket.disconnect();
    document.querySelectorAll(".intent").length === 4
    ? (count = document.querySelectorAll(".intent").length / 2)
    : (count = document.querySelectorAll(".intent").length);
  nCam = document.querySelectorAll(".intent").length;
  }, []);

 
 
  useEffect(() => {
    socket.emit("create or join", roomId);
    console.log("dashboard socket id", socket.id);
    socket.on("created", function (roomId) {
      navigator.mediaDevices
        .getUserMedia(streamConstraints)
        .then(function (stream) {
      
          localStream = stream;
          setLocalCameraStream(stream);
          localVideo.current.srcObject = stream;
          isCaller = true;
          
        })
        .catch(function (err) {
          console.log(
            "An error ocurred when accessing media devices with create",
            err
          );
        });
    });
  
    socket.on("joined", function (roomId) {
      navigator.mediaDevices
        .getUserMedia(streamConstraints)
        .then(function (stream) {
          localStream = stream;
          setLocalCameraStream(stream);
          localVideo.current.srcObject = stream;
          console.log("streamJoin", stream);
          socket.emit("ready", roomId);
          setWaiting(false);
          console.log("waiting",setWaiting)
        })
        .catch(function (err) {
          console.log(
            "An error ocurred when accessing media devices with join",
            err
          );
        });
    });

    socket.on("candidate", function (event) {
      var candidate = new RTCIceCandidate({
        sdpMLineIndex: event.label,
        candidate: event.candidate,
      });
      console.log("candidate", candidate);
      rtcPeerConnection.addIceCandidate(candidate);
      
    });

    socket.on("ready", function () {
      if (isCaller) {
        rtcPeerConnection = new RTCPeerConnection(iceServers);
        rtcPeerConnection.onicecandidate = onIceCandidate;
        rtcPeerConnection.ontrack = onAddStream;

        rtcPeerConnection.addTrack(localStream.getTracks()[0], localStream);

        rtcPeerConnection.addTrack(localStream.getTracks()[1], localStream);
        rtcPeerConnection
          .createOffer()
          .then((sessionDescription) => {
            rtcPeerConnection.setLocalDescription(sessionDescription);
            socket.emit("offer", {
              type: "offer",
              sdp: sessionDescription,
              room: roomId,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });

    socket.on("offer", function (event) {
      if (!isCaller) {
        rtcPeerConnection = new RTCPeerConnection(iceServers);
        rtcPeerConnection.onicecandidate = onIceCandidate;
        rtcPeerConnection.ontrack = onAddStream;
        rtcPeerConnection.addTrack(localStream.getTracks()[0], localStream);
        rtcPeerConnection.addTrack(localStream.getTracks()[1], localStream);
        rtcPeerConnection.setRemoteDescription(
          new RTCSessionDescription(event)
        );
        rtcPeerConnection
          .createAnswer()
          .then((sessionDescription) => {
            rtcPeerConnection.setLocalDescription(sessionDescription);
            socket.emit("answer", {
              type: "answer",
              sdp: sessionDescription,
              room: roomId,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });

    socket.on("answer", function (event) {
      rtcPeerConnection.setRemoteDescription(new RTCSessionDescription(event));
      setConnecting(true);
      console.log("connecting ",connecting)
    });

   
  }, []);




  var localStream;

  var localVideo = useRef();

  console.log("localVideo", localVideo);
  console.log("localStream", localStream);

  // handler functions
  function onIceCandidate(event) {
    if (event.candidate) {
      console.log("sending ice candidate");
      socket.emit("candidate", {
        type: "candidate",
        label: event.candidate.sdpMLineIndex,
        id: event.candidate.sdpMid,
        candidate: event.candidate.candidate,
        room: roomId,
      });
    }
  }

  function onAddStream(event) {
    document.getElementById("remoteVideo").srcObject = event.streams[0];
    console.log("remote", document.getElementById("remoteVideo").srcObject);
    let remoteStream = event.stream;
  }

  const fullScreen = (e) => {
    const elem = e.target;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  };

  return (
    <div className="dashboard_container background_main_color">
      <div className="dashboard_left_section">
      
     

        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={{ xs: 0 }}
            columns={{ xs: 4, sm: 8, md: 6.95 }}
          >
     
            
              <>
            
             
                  <div>
                    <>
                      {/* One or more Remote Camera */}
                      <Grid
                        container
                        style={{
                          width:  "86%",
                          maxHeight: "86%",
                          position: "absolute",
                          left: "30%",
                          transform: "translate(-50%,-50%)",
                          zIndex: -1,
                        }}
                        spacing={0}
                      >
                        <Grid
                          item
                          xs={
                            nCam === 4 ? 2 : nCam === 2 ? 4 : nCam === 1 ? 8 : 8
                          }
                          sm={
                            nCam === 4 ? 4 : nCam === 2 ? 4 : nCam === 1 ? 9 : 4
                          }
                          md={
                            nCam === 4
                              ? 3.475
                              : nCam === 2
                              ? 3.475
                              : nCam === 1
                              ? 7
                              : 3.475
                          }
                        >
                          <video
                            className="intent"
                            id="remoteVideo"
                            ref={remote}
                       
                            autoPlay
                            //muted={true}

                            style={{
                           
                            width: '120vw',
                            height: "100vh",
                            objectFit: "cover",
                            position: "fixed",
                            }}
                            playsInline
                            //  onClick={fullScreen}
                          />
                        </Grid>

                      
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        width={"100%"}
                        height={"100%"}
                        ref={Width}
                        sx={{
                          position: "absolute",
                          left: "50%",
                          right: "50%",
                          top: "55%",
                          transform: "translate(-50%,-50%)",
                          zIndex: 0,
                        }}
                      >
                    {/*     <audio src={Teams} loop ref={Audio} /> */}

                      </Grid>
                      <Container maxWidth={"xl"} style={{ zIndex: "1" }}>
                        <Grid
                          container
                          justifyContent={ "flex-end" }
                          alignItems="space-around"
                          spacing={ 5 }
                          style={{
                            position: "absolute",
                            right: "1%",
                            display: "bloc",
                            marginTop: "66vh",
                            marginBottom: "auto",
                          }}
                        >
                     
                         
                          <Grid item xs={1} sm={1} md={1}>
                   
                            <video
                              id="localVideo"
                              ref={localVideo}
                              width={"90%"}
                              style={{
                                zIndex: "1",
                              }}
                              autoPlay
                              muted={true}

                              playsInline
                            />
                          </Grid>
                        </Grid>
                      </Container>
                      {/*one or more Local Camera  */}

                      <MobileConversationButtons
                        handleHangUpButtonPressed={handleHangUpButtonPressed}
                        socket={socket}
                        localCameraStream={localCameraStream}
                        waiting={waiting}
                        setWaiting={setWaiting}
                      />
                    </>
                  </div>
          
              </>
            
          </Grid>
        </Box>
      </div>

    </div>
  );
};

export default MobileCallView;