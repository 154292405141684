import * as callActions from '../actions/callActions';

const initState = {
  localStream: null,
  callState: callActions.callStates.CALL_UNAVAILABLE,
  callingDialogVisible: false,
  userCallingDialogVisible: false,
  room:"",
  dataForCall:"",
  macAddress:"",
  callAccepted: false,
  callerUsername: '',
  callRejected: {
    rejected: false,
    reason: ''
  },
  remoteStream: null,
  localCameraEnabled: true,
  localMicrophoneEnabled: true,
  screenSharingActive: false,
  callerid:null,
  calleeid:null,
  message: {
    received: false,
    content: ''
  },

};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case callActions.CALL_SET_ROOM:
      return {
        ...state,
        room: action.room
      };
      case callActions.CALL_SET_DATA_FOR_CALL:
      return {
        ...state,
        dataForCall: action.dataForCall
      };
      case callActions.CALL_SET_MAC_ADDRESS:
        return {
          ...state,
          macAddress: action.macAddress
        };
    case callActions.CALL_SET_LOCAL_STREAM:
      return {
        ...state,
        localStream: action.localStream
      };
    case callActions.CALL_SET_CALL_STATE:
      return {
        ...state,
        callState: action.callState
      };
    case callActions.CALL_SET_CALLING_DIALOG_VISIBLE:
      return {
        ...state,
        callingDialogVisible: action.visible
      };
      case callActions.CALL_SET_USER_CALLING_DIALOG_VISIBLE:
        return {
          ...state,
          userCallingDialogVisible: action.visible
        };

     case callActions.CALL_SET_CALL_ACCEPTED:
        return {
          ...state,
          callAccepted: action.accepted
        };
    case callActions.CALL_SET_CALLER_USERNAME:
      return {
        ...state,
        callerUsername: action.callerUsername
      };
      case callActions.CALL_SET_CALLER_ID:
      return {
        ...state,
        callerid: action.callerid
      };
      case callActions.CALL_SET_CALLEE_ID:
      return {
        ...state,
        calleeid: action.calleeid
      };
      case callActions.CALL_SET_CALLER_EMAIL:
      return {
        ...state,
        callerUsername: action.callerEmail
      };
    case callActions.CALL_SET_CALL_REJECTED:
      return {
        ...state,
        callRejected: action.callRejected
      };
    case callActions.CALL_SET_REMOTE_STREAM:
      return {
        ...state,
        remoteStream: action.remoteStream
      };
    case callActions.CALL_SET_LOCAL_CAMERA_ENABLED:
      return {
        ...state,
        localCameraEnabled: action.enabled
      };
    case callActions.CALL_SET_LOCAL_MICROPHONE_ENABLED:
      return {
        ...state,
        localMicrophoneEnabled: action.enabled
      };
    case callActions.CALL_SET_SCREEN_SHARING_ACTIVE:
      return {
        ...state,
        screenSharingActive: action.active
      };
    case callActions.CALL_RESET_CALL_STATE:
      return {
        ...state,
        remoteStream: null,
        screenSharingActive: false,
        callerUsername: '',
        localMicrophoneEnabled: true,
        localCameraEnabled: true,
        callingDialogVisible: false
      };
    case callActions.CALL_SET_CHAT_MESSAGE:
      return {
        ...state,
        message: action.message
      };
    default:
      return state;
  }
}
;

export default reducer;
