import { combineReducers } from 'redux';
import callReducer from './reducers/callReducer';
import dataReducer from './reducers/dataReducer';
const reducers = combineReducers({

  call: callReducer,
  data:dataReducer
});

export default reducers;
