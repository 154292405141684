import React, { useState } from "react";
import appConfig from "../config/appConfig.json";
import Vizia from "../resources/Vizia.png";
import Logo_Avempace from "../resources/Logo_Avempace_2018.png";
import EmailInput from "./components/EmailInput";
import PasswordInput from "./components/PasswordInput";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";

import { useTranslation } from "react-i18next";
import Swal from "sweetalert2/dist/sweetalert2.js";
//import UsernameInput from "./components/UsernameInput";
//import SubmitButton from "./components/SubmitButton";
import { useHistory } from "react-router-dom";
import "./LoginPage.css";

import Button from "@mui/material/Button";

import Link from "@mui/material/Link";
import shortId from "shortid";

import Typography from "@mui/material/Typography";

import Axios from "axios";
import swal from "sweetalert";
//import GoToRoomInput from "../Room/goToRoomInput";

import CssBaseline from "@mui/material/CssBaseline";

import FormControlLabel from "@mui/material/FormControlLabel";

import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://avempace-wireless.com/">
        Avempace
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const SignInPage = ({ saveUsername, saveEmail, savePassword, socket }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  let [roomId, setRoomId] = useState(shortId.generate());

  const history = useHistory();
  const { t } = useTranslation();
  const loginUser = () => {
    Axios.post(appConfig.baseUrl + "/user/api/login", {
      user_email: email,
      user_password: password,
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.success) {
        //  history.push(`/dashboard/${roomId}`);
          history.push('/dashboard');
          window.location.reload(false);
          console.log("roomId", roomId);
          /*  swal("Success!", "Register successfully", "success").then((value) => {
          
          }); */

          localStorage.setItem("id", response.data.user.id);
          localStorage.setItem("email", response.data.user.email);
          localStorage.setItem("TOKEN_KEY", response.data.user.authToken);
          localStorage.setItem("firstName", response.data.user.firstName);
          localStorage.setItem("lastName", response.data.user.lastName);
        } else if (response.data.result === "error") {
          swal(t("Error!"), response.data.message, "Error!");
        }
      })
      .catch((error) => {
        console.log(error);
        swal(t("Error!"), t("Unexpected error"), t("Error!"));
      });
  };

  const handleSubmitButtonPressed = () => {
    loginUser();
  };

  return (
    <Grid xs={12} sm={8} md={5} >
      <Box sx={{ flexGrow: 1 }}  >
        <Grid className="login-page_container background " >
          <Grid item xs={6}>
            <Card
              sx={{
                width: "310px",
                height: "600px",

                position: "absolute",
                right: "6%",
                top: "40%",
                transform: "translate(0,-50%)",
              }}
              style={{ overflow: "scroll" }}
            >
              <CardContent style={{position:"relative",transform:"translateY(-50%)",top:"50%"}}>
                <CssBaseline />
                <Typography
                  variant="h4"
                  color="text.secondary"
                  style={{
                    position: "relative",
                    left: "75%",
                    transform: "translateX(-50%)",
                  }}
                >
                  <img
                    className="login-page_logo_image "
                    src={Vizia}
                    alt="ViziaLive"
                  />
                </Typography>

                <Typography variant="body2" color="text.secondary">
                  <EmailInput email={email} setEmail={setEmail} />
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <PasswordInput
                    password={password}
                    setPassword={setPassword}
                  />
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label={t("Remember me")}
                  />
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={handleSubmitButtonPressed}
                  >
                    Sign In
                  </Button>
                </Typography>

                <Typography variant="body2" color="text.secondary" ml={1}>
                  <Link
                    href="#"
                    variant="body2"
                    onClick={() => {
                      Swal.fire({
                        title: t("Enter Your Email"),
                        input: "text",
                        /*     html: `<input type="text" id="input" class="swal2-input" placeholder="Pairing code">`, */
                        inputAttributes: {
                          autocapitalize: "off",
                        },
                        showCancelButton: true,
                        confirmButtonText: "Ok",

                        confirmButtonColor: "#8CD4F5",
                        showLoaderOnConfirm: true,
                        preConfirm: () => {
                          console.log("Email", Swal.getInput().value);

                          return Axios.post(
                            appConfig.baseUrl + "/user/api/forgotpassword",
                            {
                              email: Swal.getInput().value,
                            }
                          )
                            .then((res) => {
                              console.log("responseConfirm", res.data);
                              if (!res.data.success) {
                                throw new Error(res.statusText);
                              } else {
                                if (res.data.success) {
                                }
                              }
                            })
                            .catch((error) => {
                              Swal.showValidationMessage(
                                `Request failed: ${error}`
                              );
                            });
                        },
                        allowOutsideClick: () => !Swal.isLoading(),
                      }).then((result) => {
                        if (result.isConfirmed) {
                          Swal.fire({
                            title: t(`Please check your Email`),
                          }).then();
                        } else {
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: t("Something went wrong!"),
                            html:
                           
                              t("Please try again"),
                          });
                        }
                      });
                    }}
                  >
                    {t("Forgot password?")}
                  </Link>
                </Typography>
                <br />
                <Typography
                  variant="body2"
                  color="text.secondary"
                  ml={9}
                  mt={1}
                  mb={2}
                >
                  <img
                    style={{ width: "60%", height: "60%" }}
                    src={Logo_Avempace}
                    alt="ViziaLive"
                  />
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  ml={7}
                  mb={1}
                 
                >
                  {
                    <Copyright
                      style={{
                        position: "absolute",
                        left: "50%",
                        transform: "translateX(-50%)",
                      }}
                    />
                  }
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default SignInPage;
