import React, { useState } from "react";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
const PhoneNumberInput = (props) => {
  const { phoneNumber, setPhoneNumber } = props;


  return (
    <TextField
    margin="normal"
    required
    fullWidth
    id="phoneNumber"
    label="Phone number"
    name="phone number"
    autoComplete="phone number"
    style={{ marginBottom: "13px" }}
    autoFocus
    onChange={(event) => {
      setPhoneNumber(event.target.value);
     
    }}
  />

/*     <div className="login-page_input_container">
      <input
        style={{ width:"350px", color: "#0c80ce",transform:" translate(0, 0px)",marginTop:"10px" }}
        placeholder="Phone Number "
        type="text"
        value={phoneNumber}
        onChange={(event) => {
          setPhoneNumber(event.target.value);
          setValid(
            Yup.number()
              
              .isValidSync(event.target.value)
          );
        }}
        className="login-page_input text_main_color"
      />

    </div> */
  );
};

export default PhoneNumberInput;
