import React, { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import Peer from "simple-peer";
import styled from "styled-components";
import { Row, Col } from "react-simple-flex-grid";
import { experimentalStyled as styledComponent } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import "react-simple-flex-grid/lib/main.css";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { Splitter, SplitterPanel } from "primereact/splitter";
import appConfig from "../../src/config/appConfig.json";
import { Responsive, WidthProvider } from "react-grid-layout";
// styled-components definition removed for brevity...
const ResponsiveGroupCallView = (props) => {
  const [peers, setPeers] = useState([]);
  /* let layout=[] */

  const Container = styled.div`
    //   padding: 20px;
    display: flex;
    height: 80vh;
    width: 99%;
    margin: auto;
    flex-wrap: wrap;
    border: 1px solid red;
  `;

  const StyledVideo = styled.video`
  @media (min-width: 1000px) {
    z-index: 10;
    width: peers.length===1? 100%:50% ;
    height:100vh !important ;
    object-fit: cover !important ;
    
     top:50% !important;
  
            }
   
  `;


  const Item = styledComponent(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  const Video = (props) => {
    const ref = useRef();
    useEffect(() => {
      console.log("props.peer", props.peer);
    }, [props.peer]);

    useEffect(() => {
      console.log("props", props);
      props.peer.on("stream", (stream) => {
        ref.current.srcObject = stream;
      });
    }, []);
    console.log('window.innerHeight/2',window.innerHeight/2)
    return <video className="remoteVideo"
      style={{ zIndex: 10,
        width:  peers.length===1? "100%":(peers.length===2 &&window.innerWidth>1440)?"50%":"100%",
        height:peers.length===1 ?"140vh":peers.length===2 ?(window.innerWidth>1440?"100vh":"50vh"):"50%",//:(peers.length===2 &&window.innerHeight>1000)?"50%":"auto",
        objectFit: "cover",
        border:"1px solid blue",
        top:peers.length===1 ? '50%':(peers.length===2?"-20%":'-20%'),
      //  marginTop:peers.length===2 && window.innerWidth<1285 &&"-15%"
        //  transform: peers.length===2 && 'scale(1.8)',
             }}
              playsInline autoPlay ref={ref} />;
  };

  const videoConstraints = { 
    height: window.innerHeight / 2,
    width: window.innerWidth / 2,
  };
  console.log(" window.innerHeight", window.innerHeight);
  console.log(" window.innerWidth", window.innerWidth);
  const ResponsiveGridLayout = WidthProvider(Responsive);

  const GridItemWrapper = styled.div`
    background: grey;
  `;

  const GridItemContent = styled.div`
    padding: 8px;
  `;
/*   const Root = styled.div`
  border:1px solid yellow;
  z-index:-1;
    
  `; */
  let layout = [];
  for (let i = 0; i < peers.length; i++) {
    layout.push({ i: "a" + i, w: 1, h: 1, x: i % 5, y: 0 });
  }
  console.log("layout here", layout);

  const getLayouts = () => {
    for (let i = 0; i < peers.length; i++) {
      layout.push({ i: i, w: 1, h: 1, x: i > 0 ? i - 1 : 0, y: 0 });
    }
    const savedLayouts = localStorage.getItem("grid-layout");
    console.log(
      "layout",
      savedLayouts ? JSON.parse(savedLayouts) : { lg: layout }
    );

    console.log("layoutList", layout);
    return { lg: layout };
  };

  const handleLayoutChange = (layout, layouts) => {
    localStorage.setItem("grid-layout", JSON.stringify(layouts));
  };

  useEffect(() => {
    let count = document.querySelectorAll(".remotevideo").length;
    console.log("count", count);
  }, []);

  function createPeer(userToSignal, callerID, stream) {
    const peer = new Peer({
      initiator: true,
      config: {
        iceServers: [
          {
            url: "turn:54.37.68.97:3478",
            username: "smaart",
            credential: "smaart",
          },
          { urls: "stun:stun.services.mozilla.com" },
          { urls: "stun:stun.l.google.com:19302" },
        ],
      },
      trickle: false,
      stream,
    });

    peer.on("signal", (signal) => {
      socket.emit("sending signal", { userToSignal, callerID, signal });
    });

    return peer;
  }

  function addPeer(incomingSignal, callerID, stream) {
    const peer = new Peer({
      initiator: false,
      config: {
        iceServers: [
          {
            url: "turn:54.37.68.97:3478",
            username: "smaart",
            credential: "smaart",
          },
          { urls: "stun:stun.services.mozilla.com" },
          { urls: "stun:stun.l.google.com:19302" },
        ],
      },
      trickle: false,
      stream,
    });

    peer.on("signal", (signal) => {
      socket.emit("returning signal", { signal, callerID });
    });

    peer.signal(incomingSignal);

    return peer;
  }
  useEffect(() => {
    if (peers) {
      console.log("peers use effect", peers);

      setPeers(peers);
      console.log("peers count", peers.length);
    }
  }, [peers]);

  var socket = io(appConfig.baseUrl);
  const userVideo = useRef();
  const peersRef = useRef([]);
  const roomid = props.match.params.roomid;

  useEffect(() => {
    console.log("socket", socket);
    navigator.mediaDevices
      .getUserMedia({ video:true /* videoConstraints */, audio: true })
      .then((stream) => {
        console.log("get user media");
        userVideo.current.srcObject = stream;
        socket.emit("join room", roomid);

        socket.on("all users", (users) => {
          const peers = [];
          console.log("on all users", peers);
          users.forEach((userID) => {
            const peer = createPeer(userID, socket.id, stream);
            peersRef.current.push({
              peerID: userID,
              peer,
            });
            peers.push(peer);
          });
          setPeers(peers);
        });

        socket.on("unpeer", (msg) => {
          console.log("peers before disconnect", peers);
          console.log("stream before", stream);
          console.log("unpeer", msg, "id", msg.peerId);
          console.log("peers unpeer", peers);
          setTimeout(() => {
            console.log("stream after", stream);
            peers.removeStream(stream);
            //  setPeers(peers.filter(id => id !== socket.id))
            console.log("peers after diconnect", peers);
          }, 3000);
        });
        socket.on("user joined", (payload) => {
          const peer = addPeer(payload.signal, payload.callerID, stream);
           peersRef.current.push({
            peerID: payload.callerID,
            peer,
          });

          setPeers((users) => [...users, peer]);
        });

        socket.on("receiving returned signal", (payload) => {
          const item = peersRef.current.find((p) => p.peerID === payload.id);
          item.peer.signal(payload.signal);
        });
      });
  }, []);

  return (
    <div  >
      <video style={{border:"1px solid red",zIndex:10,
                            position: "absolute",
                            right: "2vh",
                            display: "block",
                            height:"15vh", 
                            bottom:"2vh"
                          //  marginTop: "80vh",
                          //  marginBottom: "auto",
                          }}
                          muted ref={userVideo} autoPlay playsInline />
      <ResponsiveGridLayout
        layouts={{ lg: layout }}
        breakpoints={{ lg: 1500, md:1400 , sm: 500, xs: 480, xxs: 0 }}
        cols={{ lg: peers.length===1?1:(peers.length===2?2:2), md: peers.length===1?1:(peers.length===2?1:4), sm: peers.length===1?1:peers.length===2?1:3, xs: peers.length===1?1:peers.length===2?1:2, xxs: 1 }}
        rowHeight={{lg: peers.length===1?window.innerHeight:
        (peers.length===2?window.innerHeight:
        window.innerHeight/peers.length),
         md: peers.length===1?window.innerHeight:(peers.length===2?(window.innerWidth>1285?window.innerHeight:window.innerHeight/3):window.innerHeight/2), sm: peers.length===1?window.innerHeight:peers.length===2?(window.innerWidth>1285?window.innerHeight:window.innerHeight/3):window.innerHeight/2, xs: peers.length===1?window.innerHeight:peers.length===2?(window.innerWidth>1285?window.innerHeight:window.innerHeight/3):window.innerHeight/2, xxs: peers.length===2?(window.innerWidth>1285?window.innerHeight:window.innerHeight/3):window.innerHeight/2}}
        width={"100vw"}
        onLayoutChange={handleLayoutChange}
        style={{border:"1px solid green"}}
      >
        {peers.map((peer, index) => {
          console.log("===>peers ", peers.length);
          console.log("===>peer ", peer.streams[0].active);
          console.log("===>index", index);
          return (
  
         
              peer.readable === true && peer.streams[0].active === true && (
                <Video 
                key={"a" + index}
                  style={{
                    visibility: peer.readable === false && "hidden",
                  }}
                  autoPlay
                  playsInline
                //  className={classnames( peers.length===1 ? 'remotevideo':'' )}
                  id="remotevideo"
                  peer={peer}
                />
              )

          );
        })}
      
      </ResponsiveGridLayout>
    </div>
  );
};
export default ResponsiveGroupCallView;
