import React, { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import Peer from "simple-peer";
import styled from "styled-components";
import {
  MdMic,
  MdMicOff,
} from "react-icons/md";
import appConfig from "../config/appConfig.json";
const Container = styled.div`
  padding: 20px;
  display: flex;
  height: 100vh;
  width: 90%;
  margin: auto;
  flex-wrap: wrap;
`;

const StyledVideo = styled.video`
  height: 40%;
  width: 50%;
  display: none;
`;
var localStream;
const Video = (props) => {
  const ref = useRef();

  useEffect(() => {
    console.log("props", props);
    props.peer.on("stream", (stream) => {
      ref.current.srcObject = stream;
    });
  }, []);

  return <StyledVideo playsInline autoPlay ref={ref} />;
};

const videoConstraints = {
  height: window.innerHeight / 2,
  width: window.innerWidth / 2,
};

const UserCallView = (props) => {
  const [peers, setPeers] = useState([]);
  let [micState, setMicState] = useState(false);
  const socketRef = useRef();
  var socket = io(appConfig.baseUrl);
  const userVideo = useRef();
  const peersRef = useRef([]);
  const roomID = props.match.params.roomID;

  useEffect(() => {
    console.log("socket", socket);
    navigator.mediaDevices
      .getUserMedia({ video: videoConstraints, audio: true })
      .then((stream) => {
        localStream = stream;
        localStream.getAudioTracks()[0].enabled = false;
        console.log("get user media");
        userVideo.current.srcObject = stream;
        socket.emit("join room", roomID);
        console.log("join room", roomID);
        socket.on("all users", (users) => {
          const peers = [];
          console.log("on all users", peers);
          users.forEach((userID) => {
            const peer = createPeer(userID, socket.id, stream);
            peersRef.current.push({
              peerID: userID,
              peer,
            });
            peers.push(peer);
          });
          setPeers(peers);
        });

        socket.on("user joined", (payload) => {
          const peer = addPeer(payload.signal, payload.callerID, stream);
          peersRef.current.push({
            peerID: payload.callerID,
            peer,
          });

          setPeers((users) => [...users, peer]);
        });

        socket.on("receiving returned signal", (payload) => {
          const item = peersRef.current.find((p) => p.peerID === payload.id);
          item.peer.signal(payload.signal);
        });
      });
  }, []);
 
  const handleMicButtonOn = () => {
    console.log('========>handleMicButtonOn')
    let micEnabled = localStream.getAudioTracks()[0].enabled;
    localStream.getAudioTracks()[0].enabled = true;
    setMicState(micEnabled);
   
  };
  const handleMicButtonOff = () => {
    console.log('========>handleMicButtonOff')
    let micEnabled = localStream.getAudioTracks()[0].enabled;
    localStream.getAudioTracks()[0].enabled = false;
    setMicState(micEnabled);
   
  };

  function createPeer(userToSignal, callerID, stream) {
    const peer = new Peer({
      initiator: true,
      config: {
        iceServers: [
          {
            url: "turn:54.37.68.97:3478",
            username: "smaart",
            credential: "smaart",
          },
          { urls: "stun:stun.services.mozilla.com" },
          { urls: "stun:stun.l.google.com:19302" },
        ],
      },
      trickle: false,
      stream,
    });

    peer.on("signal", (signal) => {
      socket.emit("sending signal", { userToSignal, callerID, signal });
    });

    return peer;
  }

  function addPeer(incomingSignal, callerID, stream) {
    const peer = new Peer({
      initiator: false,
      config: {
        iceServers: [
          {
            url: "turn:54.37.68.97:3478",
            username: "smaart",
            credential: "smaart",
          },
          { urls: "stun:stun.services.mozilla.com" },
          { urls: "stun:stun.l.google.com:19302" },
        ],
      },
      trickle: false,
      stream,
    });

    peer.on("signal", (signal) => {
      socket.emit("returning signal", { signal, callerID });
    });

    peer.signal(incomingSignal);

    return peer;
  }

  return (
    <div style={{ backgroundColor:"black",overflow:"hidden",width:"100vw",height:"100vh"}}>
      <p style={{backgroundColor:"black !important",overflow:"hidden"}}>
        <button
          style={{
            zIndex: 100,
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            borderWidth: 4,
            borderColor:!micState ?"red": "orange",
            alignItems: "center",
            justifyContent: "center",
            width: 200,
            height: 200,
            backgroundColor:micState ? "darkGrey":'grey',
            borderRadius: 100,
            overflow:"hidden"
          }}
          onTouchStart={handleMicButtonOn}
         // onTouchMove={handleMicButtonOff}
         onTouchEnd={handleMicButtonOff}
         onTouchCancel={handleMicButtonOff}
          onMouseDown={handleMicButtonOn}
         onMouseUp={handleMicButtonOff}
       //   onMouseLeave={handleMicButtonOff}
        >
          {" "}
          {micState ? (
            <MdMicOff style={{ zIndex: -1,color:"white" }} size={50} />
          ) : (
            <MdMic style={{ zIndex: -1 ,color:"white"}} size={50} />
          )}
        </button>
      </p>

      <StyledVideo muted ref={userVideo} autoPlay playsInline />
       {peers.map((peer, index) => {
               return (
                   <Video key={index} peer={peer} />
               );
           })} 
    </div>
  );
};

export default UserCallView;
