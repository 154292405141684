// redux
import { connect } from "react-redux";
// @mui
import { styled } from '@mui/material/styles';
import { Link, Card, Typography, CardHeader, Stack,Grid } from '@mui/material';
// components
import Iconify from './Iconify';
import Moment from 'react-moment';

// ----------------------------------------------------------------------
import { useTranslation } from 'react-i18next';

const IconStyle = styled(Iconify)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 1,
  flexShrink: 0,
  marginRight: theme.spacing(2),
}));

// ----------------------------------------------------------------------


const AboutDevice = ({deviceDetails}) =>{
  const { t } = useTranslation();

  return (
    <Card style={{ height: "80vh",overflow: "auto"}}> 
      <CardHeader title={t("About")} />
            <Stack spacing={2} sx={{ p: 3,pt:0 }}>
        <Typography variant="body2">{deviceDetails && deviceDetails.materialType.description}</Typography>
        <Grid container rowSpacing={1} sx={{ pl: 0,pt:1.75 }} justifyContent="center" columnSpacing={{ xs: 1, sm: 2, md: 1}} direction={{xs:"column",md:"column"}}>
   
        <Stack sx={{mb:4}} direction="row" >
          <IconStyle icon={'bx:bx-category'} />
          <Typography variant="body2">
            {t("Type")} &nbsp; 
            <Link component="span" variant="subtitle2" color="text.primary">
            {deviceDetails && deviceDetails.materialType.device_type}
            </Link>
          </Typography>
        </Stack>

        <Stack  direction="row"  sx={{mb:4}}  >
          <IconStyle icon={'ci:barcode'} />
          <Typography variant="body2">
            {t("Product ref")} &nbsp; 
            <Link component="span" variant="subtitle2" color="text.primary">
            {deviceDetails && deviceDetails.materialType.product_ref}
            </Link>
          </Typography>
        </Stack>

        <Stack direction="row"  sx={{mb:4}} >
          <IconStyle icon={'ic:outline-add-business'} />
          <Typography variant="body2">
          { t('Manufacturer') }&nbsp;
            <Link component="span" variant="subtitle2" color="text.primary">
            {deviceDetails && deviceDetails.materialType.manufacturer}
            </Link>
          </Typography>
        </Stack>

        <Stack direction="row" sx={{mb:4}} >
          <IconStyle icon={'healthicons:i-schedule-school-date-time'} />
          <Typography variant="body2">
            {t('Created at ')}&nbsp;
            <Link component="span" variant="subtitle2" color="text.primary">
            <Moment format="DD/MM/YYYY" date= {deviceDetails && deviceDetails.createdAt} />
          
            </Link>
          </Typography>
        </Stack> 

        <Stack sx={{mb:4}} direction="row" >
          <IconStyle icon={'tabler:barcode'} />
          <Typography variant="body2">
            {t("Mac address ")}&nbsp; 
            <Link component="span" color="text.primary" style={{position:"relative",transform: "translateX(-50%)"}}>
            <Typography variant="body2">
            {deviceDetails && deviceDetails.mac_address} </Typography>
            </Link>
          </Typography>
        </Stack>
        </Grid>
      </Stack>
     
     
   
      
    </Card>
    
  );
}

function mapStoreStateToProps({ call, data }) {
  return {
    ...call,
    ...data,
  };
}
export default connect(mapStoreStateToProps, {

})(AboutDevice);
