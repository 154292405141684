import React from "react";
import "./ConversationButton.css";
import useMediaQuery from "@mui/material/useMediaQuery";

const ConversationButton = (props) => {
  const matches = useMediaQuery("(min-width:1480px)");
  const { onClickHandler } = props;
  console.log('matchesconv',matches)
  return (
    <button className={matches?"conversationBtn":"sConversationBtn"}  onClick={onClickHandler}>
      {props.children}
    </button>
  );
};

export default ConversationButton;
