import React, { useState } from "react";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
const UsernameInput = (props) => {
  const { firstname, setFirstName,lastname, setLastName} = props;



  return (
   <> <TextField
    margin="normal"
    required
    fullWidth
    id="firstname"
    label="Firstname "
    name="firstName"
    autoComplete="firstName"
    style={{ marginBottom: "13px" }}
    autoFocus
    onChange={(event) => {
      setFirstName(event.target.value);
    
    }}
  />
  <TextField
  margin="normal"
  required
  fullWidth
  id="lastname"
  label="Lastname "
  name="lastName"
  autoComplete="lastName"
  style={{ marginBottom: "13px" }}
  autoFocus
  onChange={(event) => {
    setLastName(event.target.value);
  }}
 
/></>

  );
};

export default UsernameInput;
