import React, { useState } from "react";
import NavMenuOutsiderAlerter from "../OutsiderAlerter/NavMenuOutsiderAlerter"
const  NavItem = (props) => {

    const [open, setOpen] = useState(false);

    return (
      <NavMenuOutsiderAlerter open={open} setOpen={setOpen}>   <li  className="nav-item " style={{marginRight:"2rem"}}>
        <a href="#"  className="nav-icon-button" onClick={() => setOpen(!open)}>
          {props.icon}
        </a>
        {open && props.children}
      </li>  </NavMenuOutsiderAlerter>
    );
  }

  export default NavItem ;