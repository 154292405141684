import React, { useEffect ,useState} from "react";
import Axios from "axios";
import appConfig from "../config/appConfig.json";
//mui
import {PullToRefresh} from "react-js-pull-to-refresh";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {HiPhoneMissedCall} from "react-icons/hi";
import Paper from "@mui/material/Paper";
//Loader
import { Default } from 'react-spinners-css';
//icon
import { IoVideocam, IoVideocamOffOutline } from "react-icons/io5";
//translate
import { useTranslation } from "react-i18next";
//redux
import { getUserCallEvents, getUserStopCallEvent } from "../store/actions/dataActions";
import { connect } from "react-redux";
//Moment
import Moment from "react-moment";
import {PullDownContent, ReleaseContent, RefreshContent} from "react-js-pull-to-refresh";
const EventComponent = ({ matches, userEvents,endUserEvent, getUserCallEvents, getUserStopCallEvent }) => {
  const { t } = useTranslation();
  var array=[]
  const darkTheme = createTheme({
    palette: {
      primary: {
        main: "#F5F5F5",
      },
    },
  });
  useEffect(() => {
    getUserCallEvents();
  }, []);

  userEvents.map((aUserEvent) => {
  //  console.log("aUserEvent", aUserEvent.callId);
    
    Axios.post(appConfig.baseUrl + "/user/api/getUserEndCallEventsByCallId", {
      callId: aUserEvent.callId,
    }).then((response)=>{
      array.push(response.data.events)
    })

  });


  console.log("userEvents",userEvents)
  console.log("endUserEvent",array)

  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    window.addEventListener("online", () => {
      setIsOnline(true);
    });
    window.addEventListener("offline", () => {
      setIsOnline(false);
    });
  }, [isOnline]);

  const handleRefresh = async () => {
    console.log('handleRefresh')
    await setTimeout(() => {
      window.location.reload();
    }, 10);
  };

  return  (
    <Box sx={{ flexGrow: 1 }}>

      <Grid container spacing={{ xs: 0 }} columns={{ xs: 4, sm: 8, md: 4 }}>

        <Grid
          width={"80%"}
          height={"94%"}
          style={{
            position: "absolute",
            left: matches ? "58%" : "50%",
            top: "70px",
            transform: "translateX(-50%)",
          }}
          item
          xs={6}
        >  
     
     <PullToRefresh  pullDownContent={<PullDownContent />} refreshContent={<RefreshContent />}
  pullDownThreshold={50}
  onRefresh={handleRefresh}
  triggerHeight={50}
  backgroundColor='white'
  startInvisible={true} >     <CardContent style={{border: "1px solid none"}}>
            <Box sx={{ flexGrow: 0, transform: "translate(-0%,-15%)" }}>
              <Paper elevation={1}>
                <ThemeProvider theme={darkTheme}>
                  <AppBar position="static" color="primary">
                    <Toolbar>
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1 }}
                      >
                        {/*   <p>{t('introduction')}</p> */}
                        {t("Events")}
                      </Typography>
                    </Toolbar>
                  </AppBar>
                </ThemeProvider>{" "}
              </Paper>
            </Box>
            <Paper elevation={24}>
          <Card
                sx={{ bgcolor: "#F5F5F5", height: "78vh", overflow: "auto" }}
                fixed="true"
              >
                <CardContent >
          
                    {userEvents ?(
                      userEvents.map((aUserEvent) => (
                      
                        <>
                   <ListItem style={{marginLeft:"10px"}} key={aUserEvent.eventId}>
                            {aUserEvent.eventType === "call" &&  aUserEvent.createdAt<aUserEvent.endCallAt && (
                              <>
                                <ListItemAvatar>
                                  <Avatar sx={{ bgcolor: "transparent" }}>
                                    <IoVideocam style={{ color: "black" }} />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText>
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {aUserEvent.callerName}
                                  </span>
                                  {t("Start call")}
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      marginRight: "5px",
                                    }}
                                  >
                                    {aUserEvent.calleeName}
                                  </span>
                                  <ListItemText secondary>
                                    {" "}
                                    <Moment
                                      style={{
                                        fontFamily:
                                          "Roboto, Helvetica, Arial, sans-serif",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "20px",
                                        color: "rgba(0,0,0,0.6",
                                      }}
                                      format="lll"
                                      date={aUserEvent.createdAt}
                                    />
                                  </ListItemText>
                                </ListItemText>
                              </>
                            )}
                            {aUserEvent.eventType === "leaveCall" && aUserEvent.createdAt<aUserEvent.endCallAt && (
                              <>
                                <ListItemAvatar>
                                  <Avatar sx={{ bgcolor: "transparent" }}>
                                    <IoVideocamOffOutline style={{ color: "black" }} />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText>
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                    }}
                                  >
                                      {t("End call")}
                                  </span>
                             
                                 
                                  <ListItemText secondary>
                                    {" "}
                                    <Moment
                                      style={{
                                        fontFamily:
                                          "Roboto, Helvetica, Arial, sans-serif",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "20px",
                                        color: "rgba(0,0,0,0.6",
                                      }}
                                      format="lll"
                                      date={aUserEvent.endCallAt}
                                    />
                                  </ListItemText>
                                </ListItemText>
                              </>
                            )}
                             {aUserEvent.eventType === "declineCall" && aUserEvent.createdAt<aUserEvent.endCallAt &&(
                              <>
                                <ListItemAvatar>
                                  <Avatar sx={{ bgcolor: "transparent" }}>
                                    <HiPhoneMissedCall style={{ color: "black" }} />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText>
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {aUserEvent.calleeName}
                                  </span>
                                  {t("Decline call")}
                                 {/*  <span
                                    style={{
                                      fontWeight: "bold",
                                      marginRight: "5px",
                                    }}
                                  >
                                    {aUserEvent.callerName}
                                  </span> */}
                                  <ListItemText secondary>
                                    {" "}
                                    <Moment
                                      style={{
                                        fontFamily:
                                          "Roboto, Helvetica, Arial, sans-serif",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "20px",
                                        color: "rgba(0,0,0,0.6",
                                      }}
                                      format="lll"
                                      date={aUserEvent.endCallAt}
                                    />
                                  </ListItemText>
                                </ListItemText>
                              </>
                            )}
                          </ListItem> 
                          <Divider />
                        </>
                      ))):( < Default color="#0088FF" style={{position:"absolute",top:"50%",left:"50%",transform:"translate(-50%,-50%)"}}></Default>)}
                      {userEvents.length===0 && (<div style={{height: "100vh",width: "70vw"}}>     <Typography variant="h6" sx={{textAlign:"center"/* ,marginTop:"50%" */,position:"relative",top:"38%",transform:"translateY(-50%)",zIndex:10}}>{t('No Event Found')}</Typography></div>)}
             
               
                </CardContent>
              </Card>
            </Paper>
          </CardContent></PullToRefresh>
        </Grid>
      </Grid>
    </Box> 
  );
};

function mapStoreStateToProps({ data }) {
  return {
    ...data,
  };
}
export default connect(mapStoreStateToProps, {
  getUserCallEvents,
  getUserStopCallEvent,
})(EventComponent);
