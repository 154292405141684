import React from "react";
import {
  MdCallEnd,
  MdCall,
  MdMic,
  MdMicOff,
  MdVideocam,
  MdVideocamOff,
} from "react-icons/md";
import ConversationButton from "./ConversationButton";
import { connect } from "react-redux";
import "./ConversationButton.css";
import {
  /* setScreenSharingActive, */ setLocalMicrophoneEnabled,
  setLocalCameraEnabled,
} from "../../../store/actions/callActions";


//var  screenSharingStream;
const MobileConversationButtons = (props) => {
  const {
    waiting,
    localCameraStream,
    handleHangUpButtonPressed,
    setMicrophoneEnabled,
    setCameraEnabled,
    localMicrophoneEnabled,
    matches,
    localCameraEnabled,
  } = props;

  const handleMicButtonPressed = () => {
    let micEnabled = localCameraStream.getAudioTracks()[0].enabled;
    localCameraStream.getAudioTracks()[0].enabled = !micEnabled;
    setMicrophoneEnabled(!micEnabled);
    console.log(" Microphone", localMicrophoneEnabled);
  };

  const handleCameraButtonPressed = () => {
    let cameraEnabled = localCameraStream.getVideoTracks()[0].enabled;
    localCameraStream.getVideoTracks()[0].enabled = !cameraEnabled;
    console.log("localCameraEnabled", localCameraEnabled);

    setCameraEnabled(!cameraEnabled);
  };

  return (
    <>

        <div className="mobileContainer" /* style={styles.container} */>
          {" "}
          <div   className="mobileBtnContainer">
            <ConversationButton onClickHandler={handleMicButtonPressed}>
              {localMicrophoneEnabled ? (
                <MdMic className="mobileIcon"/>
              ) : (
                <MdMicOff className="mobileIcon" />
              )}
            </ConversationButton>
        {/*     {waiting ? (
              <ConversationButton onClickHandler={handleHangUpButtonPressed}> 
                <MdCall className="mobileIcon" />
              </ConversationButton>
            ) : (
              <ConversationButton onClickHandler={handleHangUpButtonPressed}>
                <MdCallEnd className="mobileIcon"/>
              </ConversationButton>
            )} */}
            <ConversationButton onClickHandler={handleCameraButtonPressed}>
              {localCameraEnabled ? (
                <MdVideocam className="mobileIcon" />
              ) : (
                <MdVideocamOff className="mobileIcon" />
              )}
            </ConversationButton>
          </div>
        </div>
    </>
  );
};

function mapStoreStateToProps({ call }) {
  return {
    ...call,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setCameraEnabled: (enabled) => dispatch(setLocalCameraEnabled(enabled)),
    setMicrophoneEnabled: (enabled) =>
      dispatch(setLocalMicrophoneEnabled(enabled)),
  };
}
export default connect(
  mapStoreStateToProps,
  mapDispatchToProps
)(MobileConversationButtons);
