import * as dataActions from "../actions/dataActions";

const initState = {
  sentInvitations: [],
  materialTypes: [],
  searchFriend: [],
  deviceName: null,
  deviceDetails:null,
  user: null,
  callUser: null,
  invitationFriendList: [],
  userEvents:[],
  endUserEvent:[],
  deviceRecordings:[],
  myFriendsList:[]

};

const dataReducer = (state = initState, action) => {
  switch (action.type) {
    case dataActions.RETRIEVE_MATERIAL_TYPES:
      return {
        ...state,
        materialTypes: action.payload,
      };
    case dataActions.GET_SENT_INVITATIONS:
      return {
        ...state,
        sentInvitations: action.payload,
      };
      case dataActions.GET_MY_FRIENDS_LIST:
      return {
        ...state,
        myFriendsList: action.payload,
      };
    case dataActions.INVITE_USER:
      return {
        ...state,
        user: action.payload,
      };
    case dataActions.GET_INVITATIONS_LIST:
      return {
        ...state,
        invitationFriendList: action.payload,
      };
    case dataActions.SEARCH_FRIEND:
      return {
        ...state,
        friends: action.payload,
      };
    case dataActions.RETRIEVE_DEVICE_NAME:
      return {
        ...state,
        deviceName: action.payload,
      };

      case dataActions.RETRIEVE_DEVICE_DATA:
      return {
        ...state,
        deviceDetails: action.payload,
      };
    case dataActions.CALL_USER:
      return {
        ...state,
        callUser: action.payload,
      };
     
      case dataActions.GET_USER_EVENTS:
        return {
          ...state,
          userEvents: action.payload,
        };
        
        case dataActions.GET_USER_END_EVENTS:
          return {
            ...state,
            endUserEvent: action.payload,
          };
        case dataActions.GET_DEVICE_RECORDING:
          return {
            ...state,
            deviceRecordings: action.payload,
          };
  
    default:
      return state;
  }
};
export default dataReducer;
