import React, { useState, useEffect } from "react";
import appConfig from '../config/appConfig.json';
import Vizia from "../resources/Vizia.png";
import Logo_Avempace from "../resources/Logo_Avempace_2018.png";
import EmailInput from "./components/EmailInput";
import PhoneNumberInput from "./components/PhoneNumberInput";
import UsernameInput from "./components/UsernameInput";
import { useHistory } from "react-router-dom";
import "./LoginPage.css";
import swal from "sweetalert";
import Axios from "axios";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";

import Link from "@mui/material/Link";
import shortId from "shortid";

import Typography from "@mui/material/Typography";

import CssBaseline from "@mui/material/CssBaseline";

import FormControlLabel from "@mui/material/FormControlLabel";

import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import PasswordInput from "./components/PasswordInput";
import { useTranslation } from "react-i18next";
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://avempace-wireless.com/">
        Avempace
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const SignUpPage = ({ saveUsername, saveEmail, savePassword,socket }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  const history = useHistory();
  const { t } = useTranslation();

  const registerUser = () => {
    Axios.post(appConfig.baseUrl+"/user/api/signUpUser", {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      phone_number: phoneNumber,
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.success) {
          swal("Success!", "Register successfully", "success").then((value) => {
    
            history.push(`/signin`);
          });
          
        } else if (response.data.result === "error") {
          swal(t("Error!"), response.data.message, "error");
        }
      })
      .catch((error) => {
        console.log(error);
        swal("Error!", "Unexpected error", "error");
      });
  };
  const handleSubmitButtonPressed = () => {
    registerUser();
  };
  let id = localStorage.getItem("id");
  useEffect(() => {
    /* if (localStorage.getItem("TOKEN_KEY") != null) {
      return history.goBack();
    } */
  }, []);


  return (
    <Grid xs={12} sm={8} md={5}  >
    <Box sx={{ flexGrow: 1, }}>
      <Grid className="login-page_container background " >
        <Grid item xs={6} >
          <Card 
            sx={{
              width: "310px",
              height: "700px",

              position: "absolute",
              right: "6%",
              top: "50%",
              transform: "translate(0,-50%)",
            }}
            style={{ overflow: "scroll" }}
          >
            <CardContent style={{position:"relative",transform:"translateY(-50%)",top:"50%"}}>
              <CssBaseline />
              <Typography
              
                variant="h4"
                color="text.secondary"
                style={{
                  position: "relative",
                  left: "75%",
                  transform: "translateX(-50%)",
                }}
              >
                <img
                  className="login-page_logo_image "
                  src={Vizia}
                  alt="ViziaLive"
                />
              </Typography>
              <Typography variant="body2" color="text.secondary" mt={-2}>
              <UsernameInput
          firstName={firstName}
          lastName={lastName}
          setFirstName={setFirstName}
          setLastName={setLastName}
        />
              </Typography>

              <Typography variant="body2" color="text.secondary">
                <EmailInput email={email} setEmail={setEmail} />
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <PasswordInput
                  password={password}
                  setPassword={setPassword}
                />
              </Typography>
              <Typography variant="body2" color="text.secondary">
              <PhoneNumberInput
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
      />
              </Typography>
             
              <Typography variant="body2" color="text.secondary">
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 1, mb: 2 }}
                  onClick={handleSubmitButtonPressed}
                >
                  Sign up
                </Button>
              </Typography>

           
              <br />
              <Typography
                variant="body2"
                color="text.secondary"
                ml={9}
                mt={-3}
              >
                <img
                  style={{ width: "60%", height: "60%" }}
                  src={Logo_Avempace}
                  alt="ViziaLive"
                />
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
         
                ml={7}
                mb={1}
              >
                {
                  <Copyright
                    style={{
                      position: "absolute",
                      left: "50%",
                      transform: "translateX(-50%)",
                    }}
                  />
                }
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  </Grid>

  );
};



export default SignUpPage;
