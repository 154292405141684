import React, {  useEffect } from "react";
import { connect } from "react-redux";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import ListItem from "@mui/material/ListItem";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import {retrieveDeviceData,  getDeviceRecordings} from "../store/actions/dataActions"
import { FaRegWindowClose } from "react-icons/fa";
import DeviceCoverComponent from "./components/DeviceCover";
import AboutDevice from "./components/AboutDevice";
import RecordingDevice from "./components/RecordingDevice";

const DeviceDetailComponent = ({
  matches,
  setShowDeviceDetail,
  deviceDetails,
  macAddress,  
}) => {
  const darkTheme = createTheme({
    palette: {
      primary: {
        main: "#F5F5F5",
      },
    },
  });
  useEffect(() => { retrieveDeviceData(macAddress);
  //  getDeviceRecordings(macAddress)
},[])
    useEffect(() => { 
    //  getDeviceRecordings(macAddress)
    },[macAddress])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={{ xs: 0 }} columns={{ xs: 4, sm: 8, md: 4 }}>
        <Grid
          width={"80%"}
          height={"94%"}
          style={{
            position: "absolute",
            left: matches ? "58%" : "50%",
            top: "70px",
            transform: "translateX(-50%)",
          }}
          item
          xs={6}
        >
          <CardContent>
            <Box sx={{ flexGrow: 0, transform: "translate(-0%,-15%)" }}>
              <Paper elevation={1}>
                <ThemeProvider theme={darkTheme}>
                  <AppBar position="static" color="primary">
                    <Card>
                      {" "}
                      <Toolbar>
                        <Typography
                          variant="h8"
                          component="div"
                          sx={{ flexGrow: 1 }}
                        >
                          <DeviceCoverComponent /> 
                        </Typography>
                        <FaRegWindowClose
                          onClick={() => {
                            setShowDeviceDetail(false);
                          }}
                        />
                      </Toolbar>
                    </Card>
                  </AppBar>
                </ThemeProvider>{" "}
              </Paper>
            </Box>
            <Paper elevation={24}>
              <Card
                sx={{
                  bgcolor: "#F5F5F5",
                  height: "84vh",
                 // overflow: "auto",
                  mb: "2",
                }}
                fixed="true"
              >
                <CardContent>
                  <Stack
          
                    direction={{xs:"column",md:"row"}}
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                  >
                  
                  <RecordingDevice /> 
                    <AboutDevice /> 
                  </Stack>
                  <List>
                    {/* <ListItem>
                          <IoPersonAddOutline
                            style={{ paddingRight: "2%", paddingLeft: "2%" }}
                          />

                          <p style={{ font: " Segoe UI Emoji" }}>
                            <span style={{ fontWeight: "bold" }}>
                              Rim Hachiche
                            </span>
                            has joined your account.
                          </p>
                        </ListItem> */}

                    <ListItem></ListItem>
                  </List>
                </CardContent>
              </Card>
            </Paper>
          </CardContent>
        </Grid>
      </Grid>
    </Box>
  );
};

function mapStoreStateToProps({ call, data }) {
  return {
    ...call,
    ...data,
  };
}
export default connect(mapStoreStateToProps, {retrieveDeviceData, getDeviceRecordings})(DeviceDetailComponent);
