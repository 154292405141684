import React, { useState } from "react";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";

const PasswordInput = (props) => {
  const { password, setPassword } = props;
  const [valid, setValid] = useState(false);
  const [showError, setShowError] = useState(false);
  const onClick = () => setShowError(true);
  const onBlur = () => setShowError(false);
  const { t } = useTranslation();

  return (
    /*     <div className="login-page_input_container"> */

    <TextField
      margin="normal"
      required
      fullWidth
      name="password"
      label={t("Password")}
      type="password"
      id="password"
      autoComplete="current-password"
      value={password}
      onFocus={onClick}
      onBlur={onBlur}
      onChange={(event) => {
        setPassword(event.target.value);
        setValid(
          Yup.string()
            .min(2, "password is Too Short!")
            .max(8, "password is Too Long!")
            .isValidSync(event.target.value)
        );
      }}
    />

    /*    </div> */
  );
};

export default PasswordInput;
