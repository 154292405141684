import React, { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import Peer from "simple-peer";
import {
    MdMic,
    MdMicOff,
  } from "react-icons/md";
import styled from "styled-components";
import ConversationButton from "./components/ConversationButtons/ConversationButton";
import appConfig from "../../src/config/appConfig.json"
const Container = styled.div`
    padding: 20px;
    display: flex;
    height: 100vh;
    width: 90%;
    margin: auto;
    flex-wrap: wrap;
`;
var localStream;
const StyledVideo = styled.video`
    height: 40%;
    width: 50%;
`;

const Video = (props) => {
    const ref = useRef();

    useEffect(() => {
        console.log('props',props)
        props.peer.on("stream", stream => {
            ref.current.srcObject = stream;
        })
    }, []);

    return (
        <StyledVideo playsInline autoPlay ref={ref} />
    );
}


const videoConstraints = {
    height: window.innerHeight / 2,
    width: window.innerWidth / 2
};

const Room = (props) => {
    const [peers, setPeers] = useState([]);
    const [microphoneEnabled,setMicrophoneEnabled] = useState()
    const socketRef = useRef();
    var  socket = io(appConfig.baseUrl);
    const userVideo = useRef();
    const peersRef = useRef([]);
    const roomID = props.match.params.roomID;
   
    useEffect(() => {

        console.log('socket',socket)
        navigator.mediaDevices.getUserMedia({ video: videoConstraints, audio: true }).then(stream => {
           console.log('get user media')
           localStream = stream;
            userVideo.current.srcObject = stream;
            socket.emit("join room", roomID);
            console.log('join room',roomID)
            socket.on("all users", users => {
                const peers = [];
                console.log('on all users', peers)
                users.forEach(userID => {
                    const peer = createPeer(userID, socket.id, stream);
                    peersRef.current.push({
                        peerID: userID,
                        peer,
                    })
                    peers.push(peer);
                })
                setPeers(peers);
            })

            socket.on("user joined", payload => {
                const peer = addPeer(payload.signal, payload.callerID, stream);
                peersRef.current.push({
                    peerID: payload.callerID,
                    peer,
                })

                setPeers(users => [...users, peer]);
            });

            socket.on("receiving returned signal", payload => {
                const item = peersRef.current.find(p => p.peerID === payload.id);
                item.peer.signal(payload.signal);
            });
        })
    }, []);

    function createPeer(userToSignal, callerID, stream) {

        const peer = new Peer({
            initiator: true,
            config: { iceServers: [ {
                url: "turn:54.37.68.97:3478",
                username: "smaart",
                credential: "smaart",
              },
              { urls: "stun:stun.services.mozilla.com" },
              { urls: "stun:stun.l.google.com:19302" }] },
            trickle: false,
            stream,
        });

        peer.on("signal", signal => {
            socket.emit("sending signal", { userToSignal, callerID, signal })
        })

        return peer;
    }

    function addPeer(incomingSignal, callerID, stream) {
        const peer = new Peer({
            initiator: false,
            config: { iceServers: [ {
                url: "turn:54.37.68.97:3478",
                username: "smaart",
                credential: "smaart",
              },
              { urls: "stun:stun.services.mozilla.com" },
              { urls: "stun:stun.l.google.com:19302" }] },
            trickle: false,
            stream,
        })

        peer.on("signal", signal => {
            socket.emit("returning signal", { signal, callerID })
        })

        peer.signal(incomingSignal);

        return peer;
    }
    const handleMicButtonPressed = () => {
        let micEnabled = localStream.getAudioTracks()[0].enabled;
        console.log('localStream',localStream.getAudioTracks()[0].enabled)
        localStream.getAudioTracks()[0].enabled = !micEnabled;
          setMicrophoneEnabled(!micEnabled);
        console.log(" Microphone", microphoneEnabled);
      };
    return (
        <Container>
            <video style={{zIndex:10,
                            position: "absolute",
                            right: "2vh",
                            display: "block",
                            height:"15vh", 
                            bottom:"2vh"
                          //  marginTop: "80vh",
                          //  marginBottom: "auto",
                          }} muted ref={userVideo} autoPlay playsInline />
          {    peers.length>0 &&   <button style={{zIndex:11,
                            position: "absolute",
                            right: "14vw",
                            display: "block",
                     //       height:"15vh", 
                            bottom:"3vh"
                          //  marginTop: "80vh",
                          //  marginBottom: "auto",
                          }} onClick={handleMicButtonPressed}>
              {microphoneEnabled ? (
                <MdMic />
              ) : (
                <MdMicOff  />
              )}
            </button>   }   
            {peers.map((peer, index) => {
                return (
                    peer.readable === true && peer.streams[0].active === true && (<Video   style={{visibility: peer.readable === false && "hidden", }} key={index} peer={peer} />)
                );
            })}
        </Container>
    );
};

export default Room;
