import { useRef, useState,useEffect } from "react";
// redux
import { connect } from "react-redux";
import {getDeviceRecordings} from "../../store/actions/dataActions";

// @mui
import { Card ,CardHeader} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
// components
import moment from "moment";
import { Player, BigPlayButton } from "video-react";
import { styled } from '@mui/material/styles';
import {CardContent,Typography} from '@mui/material';
// ----------------------------------------------------------------------

import "video-react/dist/video-react.css";
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
// ----------------------------------------------------------------------
const CaptionStyle = styled(CardContent)(({ theme }) => ({
 // ...cssStyles().bgBlur({ blur: 2, color: 'grey' }),
  bottom: 0,
  width: '10%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  justifyContent: 'space-between',
  color: theme.palette.common.white,
}));
const RecordingDevice = ({ deviceRecordings,macAddress,getDeviceRecordings }) => {
  let [path, setPath] = useState("");
  let [recordType, setRecordType] = useState("");
  let [createdAt, setCreatedAt] = useState("");
  const vidRef = useRef(null);
console.log('macAdressRecording',macAddress)
useEffect(() => { 
  getDeviceRecordings(macAddress);
  console.log("deviceRec",deviceRecordings)},[macAddress])


  return (
    <Card style={{ height: "80vh", overflow: "auto", width: "80%"}}>
 

      {deviceRecordings.records!==undefined && deviceRecordings.records.length!==0 ?(
         <> <CardHeader  style={{
          width: "85%",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "10px",
        }} title={
      <> <Typography
                sx={{ display: 'inline',float:'left'}}
                component="div"
                variant="h6"
                color="text.primary"
              >
             
            
              { recordType||deviceRecordings.records!==undefined && deviceRecordings.records[0].recordType}
              </Typography> 

              <Typography
              sx={{ display: 'inline',float:'right' }}
              component="div"
              variant="body2"
              color="text.primary"
            >
            { (createdAt || moment(deviceRecordings.records!==undefined && deviceRecordings.records[0].createdAt).format("lll"))} 
            </Typography></>}
             /><Card
        mt={2}
        spacing={2}
        sx={{
          width: "85%",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "10px",
        }}
      >
      <CaptionStyle>
      </CaptionStyle>
 
<Player
     
          ref={vidRef}
          style={{ width: "100%", marginTop: "20px" }}
          src={ path||deviceRecordings.records!==undefined && deviceRecordings.records[0].publicUrl}
        >
          <BigPlayButton position="center" />
        </Player>

      </Card></>):( <Typography variant="h6" sx={{textAlign:"center",marginTop:"32%"}}>No Recording Found</Typography>)}
     
      <Card
        style={{
          width: "85%",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "10px",
        }}
      >
        
        {deviceRecordings &&
          deviceRecordings.records &&
          deviceRecordings.records.map((aRecording) => (
            <>
              <List
                onClick={() => {
                  console.log("url" +aRecording.publicUrl);
                  setPath(aRecording.publicUrl);
                  setRecordType(aRecording.recordType);
                  setCreatedAt(moment(aRecording.createdAt).format("lll"));
                  vidRef.current.play();
                }}
                style={{
                  width: "100%",
                  backgroundColor: "#fdfdfd",
                  cursor: "pointer",
                }}
              >
             <ListItem style={{ width: "100%", cursor: "pointer" }}>
             <DirectionsRunIcon/>   
              <ListItemText
                    primaryTypographyProps={{fontSize: '20px'}} 
                    style={{ position: "relative", left: "12px" }}
                    primary={aRecording.recordType}
                    secondary={ moment(aRecording.startTime).format("ll h:mm:ss A")  }
                  />
               
            
               <ListItemText
                    primaryTypographyProps={{fontSize: '20px'}} 
                    style={{ position: "absolute", left: "52px",top:"14vh" }}
               
                    secondary={   moment(new Date(aRecording.endTime).getTime()- new Date(aRecording.startTime).getTime()).format(" mm:ss ")   }
                  />
           <div style={{
                      width: "25%",
                      display: "block",
                      marginTop: "auto",
                      marginBottom: "auto",
                      position: "relative",
                      right: "-12px",
                    }}>    <Player
     
                    id="myVid"
                    style={{
                     width:"10%",
                     height:"10%",
                      //width: "20%",
                      display: "block",
                      marginTop: "auto",
                      marginBottom: "auto",
                      position: "relative",
                      right: "-12px",
                    }}
                    src={aRecording.publicUrl}
                    muted
                    
                    onPlay={() => {
                      setPath(aRecording.publicUrl);
                      setRecordType(aRecording.recordType);
                      setCreatedAt(moment(aRecording.createdAt).format("lll"));
                      
                    }}
                  >
                    <BigPlayButton position="center" />
                  </Player> </div>
                 

             {/*      <video
                 
                    id="myVid"
                    style={{
                      width: "20%",
                      display: "block",
                      marginTop: "auto",
                      marginBottom: "auto",
                      position: "relative",
                      right: "-12px",
                    }}
                    onClick={() => {
                      console.log("url", aRecording.url);
                      setUrl(aRecording.url);
                      setTitle(aRecording.title);
                      setCreatedAt(moment(aRecording.createdAt).format("lll"));
                      vidRef.current.play();
                    }}
              
                    src={aRecording.url}
                 />    */}
                </ListItem>
             
              
             
              </List>{" "}
              <Divider />
            </>
          ))}
      </Card>
    </Card>
  );
};

function mapStoreStateToProps({ call, data }) {
  return {
    ...call,
    ...data,
  };
}
export default connect(mapStoreStateToProps, {getDeviceRecordings})(
  RecordingDevice 
);
