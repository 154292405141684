import React, { useState, useEffect, useRef } from "react";
import appConfig from "../../../config/appConfig.json";
import { CSSTransition } from "react-transition-group";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Axios from "axios";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import userAvatar from "../../../resources/userAvatar.png";
import { connect } from "react-redux";
import { getInvitationsList,getMyFriendsList} from "../../../store/actions/dataActions";
const DropdownNotificationMenu = ({ invitationFriendList ,getInvitationsList,getMyFriendsList,myFriendsList}) => {
  const [activeMenu, setActiveMenu] = useState("main");
  const [menuHeight, setMenuHeight] = useState(null);
  const [senderEmail, setSenderEmail] = useState("");
  const [senderId, setSenderId] = useState();
  const dropdownRef = useRef(null);
  console.log("invitationFriendListee", invitationFriendList);
  const handleClick = (senderEmail) => {
    setSenderEmail(senderEmail);
  };
  const acceptInvitation = (senderEmail) => {
    let emailUser = localStorage.getItem("email");
    Axios.post(appConfig.baseUrl + "/user/api/acceptInvitation", {
      emailUser: emailUser,
      senderEmail: senderEmail,
    })
      .then((response) => {
      //window.location.reload(false);
      getInvitationsList();
      getMyFriendsList();

       // console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleSenderId = (senderId) => {
    setSenderId(senderId);
  };

  const declineInvitation = (senderId) => {
    let anInvitedFriendEmail = localStorage.getItem("email");
    Axios.post(appConfig.baseUrl + "/user/api/removeinvitation", {
      anInvitedFriendEmail: anInvitedFriendEmail,
      aUserId: senderId,
    })
      .then((response) => {
        //window.location.reload(false);
        getInvitationsList();
        getMyFriendsList()
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setMenuHeight(dropdownRef.current?.firstChild.offsetHeight);
  }, []);

  function calcHeight(el) {
    const height = el.offsetHeight;
    setMenuHeight(height);
  }
  console.log("height", menuHeight);
  const { t } = useTranslation();
  //const history = useHistory();
  return (
    <div
      className="dropdown"
      style={{
        height: menuHeight,
        width: "32%",
        zIndex: "9",
        marginLeft: "20%",
        backgroundColor: "#DBE8F0",
        overflow: "hidden",
      }}
      ref={dropdownRef}
    >
      <CSSTransition
        in={activeMenu === "main"}
        timeout={500}
        classNames="menu-primary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <Box sx={{ flexGrow: 1 }} className="menu">
          {invitationFriendList.map((activeUser) => (
            <Grid
              style={{
                backgroundColor: "#c7dfed",
                marginBottom: "20px",
                paddingBottom: "10px",
                borderRadius: "5px",
              }}
              container
              spacing={1}
            >
              <Grid item xs={1}>
                <img
                  style={{
                    width: "25px",
                    height: "30px",
                    marginTop: "5%",
                    marginLeft: "35%",
                  }}
                  alt="img"
                  src={activeUser.photo_url || userAvatar}
                />
              </Grid>
              <Grid
                item
                sx={{ mt: 0.5 }}
                xs={2.7}
                style={{
                  color: "black",
                  textAlign: "center",
                  verticalAlign: " middle",
                }}
              >
                {activeUser.firstName} {activeUser.lastName}
              </Grid>
              <Grid item justify="center" alignItems="center" xs>
                <Button
                  style={{ width: "95px" }}
                  size="small"
                  onClick={() => {
                    handleClick(activeUser.email);
                    acceptInvitation(activeUser.email);
                  }}
                  variant="contained"
                >
                  {t("Confirm")}
                </Button>
              </Grid>
              <Grid item alignItems="center" justify="center" xs>
                <Button
                  style={{ width: "95px" }}
                  size="small"
                  onClick={() => {
                    handleSenderId(activeUser.id);
                    declineInvitation(activeUser.id);
                  }}
                  variant="outlined"
                >
                  {t("Delete")}
                </Button>
              </Grid>
            </Grid>
          ))}
        </Box>
      </CSSTransition>
    </div>
  );
};
function mapStoreStateToProps({ call, data }) {
  return {
    ...call,
    ...data,
  };
}
export default connect(mapStoreStateToProps, {
  getInvitationsList,
  getMyFriendsList
})(DropdownNotificationMenu);
