import React, { useEffect, useState, useRef } from "react";
//@redux
import { connect } from "react-redux";
import store from "../store/store";
import Axios from "axios";
//import io from "socket.io-client";
import appConfig from "../config/appConfig.json";
//@mui
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Container } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
//Loader

import { Default } from 'react-spinners-css';
//@css
import * as classe from "./Options.module.css";
import "react-pro-sidebar/dist/css/styles.css";
import "./CallView.css";
import "./components/Navbar/Navbar.css";
//component
import userAvatar from "../resources/userAvatar.png";
import Teams from "../resources/teams.mp3";
import Ring from "../resources/ringing.wav"
import NavMenu from "./components/NavMenu/NavMenu";
import DeviceDetailComponent from "../DeviceDetails/DeviceDetails";
import EventComponent from "../Event/Event";
import ConversationButtons from "../CallView/components/ConversationButtons/ConversationButtons";
import ProSideBarComponent from "./components/SideBar/ProSideBar";
import useSound from 'use-sound';
import {
  setCallingDialogVisible,
  setUserCallingDialogVisible,
  setCallAccepted,
  setLocalStream,
  setRemoteStream,
  setCallerUsername,
  setRoom,
  setCallerID,
  setCalleeID,
} from "../store/actions/callActions";
import {
  retrieveMaterialTypes,
  getInvitationsList,
} from "../store/actions/dataActions";
import { Scale } from "@mui/icons-material";
let count;
let nCam;
var roomNumber;
var cameraEnabled;
const CallView = (props) => {
  const {
    socket,
    callingDialogVisible,
    userCallingDialogVisible,
    callAccepted,
    remoteStream,
    retrieveMaterialTypes,
    getInvitationsList,
    callerUsername,
    callerid,
    calleeid,
    room,
    visible,
  } = props;


  var iceServers = {
    iceServers: [
      {
        urls: "turn:54.37.68.97:3478",
        username: "smaart",
        credential: "smaart",
      }
    ],
  }; 


  var localStream = store.getState().call.localStream;
  var localVideo = useRef();

  const Width = useRef(null);
  const remote = useRef();
  var isCaller;
  var rtcPeerConnection = useRef();
  var isLocalVideoStarted = false;

  var mac_address = useRef();

  const [macAddress, setMacAddress] = useState(null);
  const [callerPhoto, setCallerPhoto] = useState(null);
  const [waiting, setWaiting] = useState(true);
  const [showCallView, setShowCallView] = useState(false);
  const [showDeviceDetail, setShowDeviceDetail] = useState(false);
  const [loading, setLoading] = useState(true);
  const [playHere, setPlay] = useState(false);

  var streamConstraints = { audio: true, video: true};

  // handler functions
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);
  const connectWithWebSocket = () => {
    socket.on("connection", () => {
      console.log("succesfully connected with socket server");
      console.log(socket.id);
      let userId = localStorage.getItem("id");
      console.log("user_connected userId: " + userId);
      socket.emit("user_connected", userId);
      // initLocalMediaDevices();
    });
    socket.on("disconnect", () => { let userId = localStorage.getItem("id");
    socket.emit("user_disconnected", userId);});
    socket.on("end-call", (event, room) => {
    
      store.dispatch(setCallingDialogVisible(false));
      store.dispatch(setUserCallingDialogVisible(false));
      console.log('setShowCallViewFalse1')
     // setShowCallView(false);
      stopCamera();
      window.location.reload(false);
      console.log("end call socket");
    });
  };

  socket.on("decline-call", function () {
    console.log("call declined socket");
    stopCamera();
  });
  var initLocalMediaDevices = function () {
    setWaiting(false);
    navigator.mediaDevices
      .getUserMedia(streamConstraints)
      .then(function (stream) {
        localStream = stream;
        store.dispatch(setLocalStream(stream));
        localVideo.current.srcObject = stream;
        console.log("user_calling LocalCamera srcObject", localVideo.current.srcObject);
        isLocalVideoStarted = true;
        localVideo.onloadedmetadata = function (e) {
          localVideo.play();
        };
      })
      .catch(function (err) {
        console.log("An error ocurred when accessing media devices", err);
      });
  };
const stopCamera=()=>{

  localStream.getTracks().forEach((t) => {
    console.log('local stream get',t)
    t && t.stop();
   localStream.getVideoTracks()[0].enabled = false;
   console.log('stopLocalCamera ',t)});
   console.log('stopLocalCamera enabled',localStream.getVideoTracks()[0], "is " ,localStream.getVideoTracks()[0].enabled);
   localStream.srcObject = null;
}
  const handleHangUpButtonPressed = () => {
   
    console.log("socket.emit user-hanged-up ");
    console.log(
      "aCallerId" +
        callerid +
        "aCalleeId" +
        calleeid +
        "roomNumber" +
        store.getState().call.room
    );

    stopCamera();
    socket.emit("user-hanged-up");
    socket.emit("leave-room", {
      roomNumber: store.getState().call.room || roomNumber,
      macAddress: macAddress,
    });
    console.log('setShowCallViewFalse3')
    window.location.reload(false);
   // setShowCallView(false);
    Axios.post(appConfig.baseUrl + "/user/api/endCallUsertoUser", {
      calleeId: calleeid,
      callerId: callerid,
      roomNumber: store.getState().call.room || roomNumber,
    })
      .then((response) => {
     //   setLoading(true)
       // window.location.reload(false);
       stopCamera();
     //   setShowCallView(false)
     console.log('setShowCallViewFalse4')
     window.location.reload(false);
        console.log("callEnd SUCCESS " + JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log("callEnd ERROR " + error);
      });

    // socket.emit("user-hanged-up", roomNumber);

    stopCamera();
    // window.location.reload(false);
  };

  const handleDeclineCall = () => {
    console.log("socket.emit user-hanged-up decline");
    console.log(
      "aCallerId" +
        callerid +
        "aCalleeId" +
        calleeid +
        "roomNumber" +
        store.getState().call.room
    );
    socket.emit("user-hanged-up");
    stopCamera();
    Axios.post(appConfig.baseUrl + "/user/api/declineCallUsertoUser", {
      calleeId: calleeid,
      callerId: callerid,
      roomNumber: store.getState().call.room || roomNumber,
    })
      .then((response) => {
        console.log("callEnd SUCCESS " + JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log("callEnd ERROR " + error);
      });

     window.location.reload(false);

   // setShowCallView(false);
    //setUserCallingDialogVisible(false);
  };
  //$$
const notifyForCall = ()=>{
  Axios.post(appConfig.baseUrl + "/user/api/notifyUserForCall", {
    roomInfo:store.getState().call.dataForCall.roomInfo,
    userSockets:store.getState().call.dataForCall.usersSockets
  })
    .then((response) => {
      if(response.data.success){
      console.log("notifyUserForCall","calleeId",store.getState().call.dataForCall.roomInfo.theCalleeId,)
      }
      else{
        console.log("notifyUserForCall error")
      }
    })
    .catch((error) => {
      console.log("notifyUserForCall ERROR " + error);
    });
}
  function onIceCandidate(event) {
    if (event.candidate) {
      console.log("sending ice candidate", event.candidate);
   // If a srflx candidate was found, notify that the STUN server works!
   if(event.candidate.type === "srflx"){
    console.log("The STUN server is reachable!");
    console.log(`   Your Public IP Address is: ${event.candidate.address}`);
}

// If a relay candidate was found, notify that the TURN server works!
if(event.candidate.type === "relay"){
    console.log("The TURN server is reachable !");
}
      socket.emit("candidate", {
        type: "candidate",
        label: event.candidate.sdpMLineIndex,
        id: event.candidate.sdpMid,
        candidate: event.candidate.candidate,
        room: roomNumber||store.getState().call.room,
      });
      setWaiting(false);
    }
  }

  function onAddStreamReady(event) {
    console.log("remote_stream Size Ready" + event.streams.length);

    remote.current.srcObject = event.streams[0];
   

    console.log("remote_stream", event.streams[0]);
    //  store.dispatch(setRemoteStream(event.streams[0]));
  }

  function onAddStreamOffer(event) {
    console.log("remote_stream Size Offer" + event.streams.length);
  
    remote.current.srcObject = event.streams[0];
  
    console.log("remote_stream[0]", event.streams[0]);
    // store.dispatch(setRemoteStream(event.streams[0]));
  }
  useEffect(() => {
    //enterStaticRoom()
    console.log("width element");
    connectWithWebSocket();
    let userId = localStorage.getItem("id");
    return () => {
      socket.disconnect();
      socket.emit("user_disconnected", userId);
      window.location.reload();
    };
  }, []);

  function enterStaticRoom() {
    //initLocalMediaDevices();

    console.log("theData :" + enterStaticRoom);

    roomNumber = "6";
    //store.dispatch(setRoom(roomNumber));
    setMacAddress("FF:FF:FF:FF:FF:FF");
    console.log("roomNumbeerDevice", roomNumber);

    // Show POPup to accept call
    // save roomNumber to use when click on accpet button

    console.log("---->testRoom :" + roomNumber);
    socket.emit("testRoom", roomNumber);

    console.log("---->create or join :" + roomNumber);
    socket.emit("create or join", roomNumber);
  }

  useEffect(() => {
    retrieveMaterialTypes();
    getInvitationsList();
    //initLocalMediaDevices();
    console.log("roomUseEffect", store.getState().call.room);

    socket.on("user_calling", function (data) {
      console.log("user_calling LocalCamera");
      initLocalMediaDevices();
      store.dispatch(setUserCallingDialogVisible(true));
      //setPlay(true)
      
      console.log('playing')
      console.log('play1',playHere)
  
      setShowCallView(true);
      console.log("data", data);
      store.dispatch(setCalleeID(data.theCalleeId));
      store.dispatch(setCallerID(data.theCallerId));
      roomNumber = data.roomNumber;
      store.dispatch(setCallerUsername(data.theCallerName));
      setCallerPhoto(data.theCallerPhoto);
    });

    socket.on("device_calling", function (data) {
      initLocalMediaDevices();
      console.log("device_calling :" + JSON.stringify(data));
      setShowCallView(true);
      store.dispatch(setCallingDialogVisible(true));
      console.log("theData :" + JSON.stringify(data));
      console.log("data", data);
      console.log("macAddress :" + data.macAddress);
      store.dispatch(setRoom(data.roomNumber));

      roomNumber = data.roomNumber;

      setMacAddress(data.macAddress);

      console.log("roomNumberrrDevice", roomNumber);
      console.log("macAddressDevice", mac_address);
     
    });

    socket.on("user-hanged-up", function (roomNumber) {
      console.log(
        "socket.on user-hanged-up ",
        callerid,
        " ",
        calleeid,
        "roomNumber",
        store.getState().call.room
      );
       //  window.location.reload(false);
    
     stopCamera();
     console.log('setShowCallViewFalse6')
     window.location.reload(false);
     // setShowCallView(false);
    });

    socket.on("device_call_order", function (data) {
      initLocalMediaDevices();
      setShowCallView(true);
      console.log("device_call_order :" + JSON.stringify(data));
      console.log("theData :" + JSON.stringify(data));
      console.log("macAddress :" + data.macAddress);
      roomNumber = data.roomNumber;
      isCaller = false;
      store.dispatch(setRoom(data.roomNumber));
      setMacAddress(data.macAddress);
      console.log("roomNumberrrDevice", roomNumber);
      console.log("macAddressDevice", mac_address);

      setTimeout(function () {
        socket.emit("create or join", roomNumber);
      }, 1000);
    });

    socket.on("device_not_ready", function (data) {
      console.log("device_not_ready :" + JSON.stringify(data));
     // window.location.reload(false);
      console.log("roomId :" + data.roomNumber);
      console.log("macAddress :" + data.macAddress);

      roomNumber = data.roomNumber;
    });

    socket.on("invitation-coming", function (data) {
      console.log("you have an invitation");
      getInvitationsList();
    });
    socket.on("leave-room", function (data) {
      console.log("leave-room :" + JSON.stringify(data));
      window.location.reload(false);
        store.dispatch(setCallingDialogVisible(false));
      store.dispatch(setUserCallingDialogVisible(false));
     // setShowCallView(false);
      roomNumber = data.roomNumber;
    });

    socket.on("created", function (roomNumber) {
      

      console.log("On created :" + roomNumber);
      if (isLocalVideoStarted) {
        isCaller = true;
        notifyForCall()
      } else {
        console.log("-----> navigator.mediaDevices.getUserMedia");
        navigator.mediaDevices
          .getUserMedia(streamConstraints)
          .then(function (stream) {
            localStream = stream;
            store.dispatch(setLocalStream(stream));
            localVideo.current.srcObject = stream;
            console.log("srcObject", localVideo.current.srcObject);
            isCaller = true;
            console.log("-----> POST notifyUserForCall API ");
            notifyForCall()
          })
          .catch(function (err) {
            console.log(
              "An error ocurred when accessing media devices with create",
              err
            );
          });
      }
    });

    socket.on("joined", function (roomNumber) {
      console.log("On joinedd :" + roomNumber + " - isLocalVideoStarted");

      if (isLocalVideoStarted) {
        console.log("emit ready");
        isCaller = false;
        socket.emit("ready", roomNumber);
      } else {
        console.log("On joined getUserMedia");
        navigator.mediaDevices
          .getUserMedia(streamConstraints)
          .then(function (stream) {
            localStream = stream;
            store.dispatch(setLocalStream(stream));
            localVideo.current.srcObject = stream;
            console.log("srcObject", localVideo.current.srcObject);
            isCaller = false;
            console.log("Emit ready " + roomNumber);
            socket.emit("ready", roomNumber);
            localVideo.onloadedmetadata = function (e) {
              localVideo.play();
            };
          })
          .catch(function (err) {
            console.log(
              "An error ocurred when accessing media devices with join",
              err
            );
          });
      }
    });
    socket.on("candidate", function (event) {
      var onCandidateState = rtcPeerConnection.iceConnectionState;
      console.log('onCandidateState',onCandidateState);
      var candidate = new RTCIceCandidate({
        sdpMLineIndex: event.label,
        candidate: event.candidate,
      });
      console.log("candidate" + JSON.stringify(candidate));
      rtcPeerConnection.addIceCandidate(candidate);
    });

    socket.on("ready", function () {
      console.log("On ready - isCaller: " + isCaller);
      console.log("On ready room Number: " + store.getState().call.room);
      console.log("rtcPeerConnection.iceConnectionState ready",rtcPeerConnection.iceConnectionState)
      if (isCaller) {
        rtcPeerConnection = new RTCPeerConnection(iceServers);
        rtcPeerConnection.addEventListener('iceconnectionstatechange', function(e) {
          console.log('ice state change', rtcPeerConnection.iceConnectionState);
       });
        var readyState = rtcPeerConnection.iceConnectionState;
        console.log('readyState',readyState);
        rtcPeerConnection.onicecandidate = onIceCandidate;
        rtcPeerConnection.ontrack = onAddStreamReady;
        console.log("tracks Ready ", localStream.getTracks());
        localStream.getTracks().map((aTrack) => {
          console.log("aTrack Ready ", aTrack);
          rtcPeerConnection.addTrack(aTrack, localStream);
        });
       
        store.dispatch(setCallingDialogVisible(false));
        store.dispatch(setUserCallingDialogVisible(false));
        store.dispatch(setCallAccepted(true));
        rtcPeerConnection
          .createOffer({ iceRestart: true })
          .then((sessionDescription) => {
            rtcPeerConnection.setLocalDescription(sessionDescription);
            console.log(
              "Emit offer room number: " + store.getState().call.room
            );
            socket.emit("offer", {
              type: "offer",
              sdp: sessionDescription,
              room: store.getState().call.room,
            });
          })
          .catch((error) => {
            console.log("----> Create offer error");
            console.log(error);
          });

          rtcPeerConnection.oniceconnectionstatechange = function(event) {

            console.log("restart iceConnectionState change offer",event.target.iceConnectionState)

            if ( rtcPeerConnection.iceConnectionState == "disconnected") {
      
             // Retry CALL
             socket.emit("retry-call", {
             retryInfo: store.getState().call.dataForCall.roomInfo
            });
           
            }else if (rtcPeerConnection.iceConnectionState == "connected"){

             /* socket.emit("retry-call", {
                retryInfo: store.getState().call.dataForCall.roomInfo
               });*/
            }
          }
      }
    });

    socket.on("offer", function (anOffer) {
      console.log("On offer - received");
      console.log("On offer - isCaller: " + isCaller);
      console.log("On offer - roomNumber: " + roomNumber);
      
      if (!isCaller) {
        rtcPeerConnection = new RTCPeerConnection(iceServers);
        rtcPeerConnection.addEventListener('iceconnectionstatechange', function(e) {
          console.log('ice state change', rtcPeerConnection.iceConnectionState);
       });
        rtcPeerConnection.onicecandidate = onIceCandidate;
        rtcPeerConnection.ontrack = onAddStreamOffer;
        var offerState = rtcPeerConnection.iceConnectionState;
        console.log("rtcPeerConnection.iceConnectionState offer",rtcPeerConnection.iceConnectionState)
        console.log('offerState',offerState);
        console.log("tracks Offer ", localStream.getTracks());
        localStream.getTracks().map((aTrack) => {
          console.log("aTrack Offer", aTrack);
          rtcPeerConnection.addTrack(aTrack, localStream);
        });
        rtcPeerConnection.setRemoteDescription(
          new RTCSessionDescription(anOffer)
        );
        rtcPeerConnection
          .createAnswer(/* { iceRestart: true } */)
          .then((sessionDescription) => {
            rtcPeerConnection.setLocalDescription(sessionDescription);
            socket.emit("answer", {
              type: "answer",
              sdp: sessionDescription,
              room: roomNumber,
            });
          })
          .catch((error) => {
            console.log(error);
          });
          rtcPeerConnection.oniceconnectionstatechange = function(event) {
            console.log("iceConnectionState change answer",event.target.iceConnectionState)
            if (rtcPeerConnection.iceConnectionState == "failed" || rtcPeerConnection.iceConnectionState == "disconnected") {
              console.log('rtcPeerConnection.iceConnectionState Answer failed')
             
              rtcPeerConnection = new RTCPeerConnection(iceServers);
              rtcPeerConnection.onicecandidate = onIceCandidate;
              rtcPeerConnection.ontrack = onAddStreamOffer;
              var offerState = rtcPeerConnection.iceConnectionState;
              console.log("rtcPeerConnection.iceConnectionState offer",rtcPeerConnection.iceConnectionState)
              console.log('offerState',offerState);
              localStream.getTracks().map((aTrack) => {
                console.log("aTrack Offer", aTrack);
                rtcPeerConnection.addTrack(aTrack, localStream);
              });
              rtcPeerConnection.setRemoteDescription(
                new RTCSessionDescription(anOffer)
              );
              rtcPeerConnection.createAnswer({ iceRestart: true })
              .then((sessionDescription) => {
                console.log("----> reCreate Answer ");
                rtcPeerConnection.setLocalDescription(sessionDescription);
                socket.emit("answer", {
                  type: "answer",
                  sdp: sessionDescription,
                  room: roomNumber,
                });
              })
              .catch((error) => {
                console.log(error);
              });
            }
          }
      }
    });

    socket.on("answer", function (event) {
      console.log("On answer");
      rtcPeerConnection.setRemoteDescription(new RTCSessionDescription(event));
    });

    socket.on("handle-retry-call", function (data) {
      console.log("On handle-retry-call");
      console.log("Data: "+JSON.stringify(data));
      store.dispatch(setRoom(data.retryInfo.roomNumber));
      socket.emit("create or join", data.retryInfo.roomNumber);
        rtcPeerConnection = new RTCPeerConnection(iceServers);
        rtcPeerConnection.addEventListener('iceconnectionstatechange', function(e) {
          console.log('ice state change', rtcPeerConnection.iceConnectionState);
       });
        var readyState = rtcPeerConnection.iceConnectionState;
        console.log('readyState',readyState);
        rtcPeerConnection.onicecandidate = onIceCandidate;
        rtcPeerConnection.ontrack = onAddStreamReady;
        console.log("tracks Ready ", localStream.getTracks());
        localStream.getTracks().map((aTrack) => {
          console.log("aTrack Ready ", aTrack);
          rtcPeerConnection.addTrack(aTrack, localStream);
        });
     
        store.dispatch(setCallingDialogVisible(false));
        store.dispatch(setUserCallingDialogVisible(false));
        store.dispatch(setCallAccepted(true));
        rtcPeerConnection
          .createOffer({ iceRestart: true })
          .then((sessionDescription) => {
            rtcPeerConnection.setLocalDescription(sessionDescription);
            console.log(
              "Emit offer room number: " + data.retryInfo.roomNumber ||store.getState().call.room||roomNumber
            );
            socket.emit("offer", {
              type: "offer",
              sdp: sessionDescription,
              room:  data.retryInfo.roomNumber  ||store.getState().call.room|| roomNumber,
            });
          })
          .catch((error) => {
            console.log("----> Create offer error");
            console.log(error);
          });

          
    socket.on("offer", function (anOffer) {
      console.log("On offer retry- received");
      console.log("On offer  retry- isCaller: " + isCaller);
      console.log("On offer  retry- roomNumber:  " +  data.retryInfo.roomNumber );
        rtcPeerConnection = new RTCPeerConnection(iceServers);
        rtcPeerConnection.addEventListener('iceconnectionstatechange', function(e) {
          console.log('ice state change', rtcPeerConnection.iceConnectionState);
       });
        rtcPeerConnection.onicecandidate = onIceCandidate;
        rtcPeerConnection.ontrack = onAddStreamOffer;
        var offerState = rtcPeerConnection.iceConnectionState;
        console.log("rtcPeerConnection.iceConnectionState offer",rtcPeerConnection.iceConnectionState)
        console.log('offerState',offerState);
        console.log("tracks Offer ", localStream.getTracks());
        localStream.getTracks().map((aTrack) => {
          console.log("aTrack Offer", aTrack);
          rtcPeerConnection.addTrack(aTrack, localStream);
        });
        rtcPeerConnection.setRemoteDescription(
          new RTCSessionDescription(anOffer)
        );
        rtcPeerConnection
          .createAnswer()
          .then((sessionDescription) => {
            rtcPeerConnection.setLocalDescription(sessionDescription);
            socket.emit("answer", {
              type: "answer",
              sdp: sessionDescription,
              room: data.retryInfo.roomNumber,
            });
          })
          .catch((error) => {
            console.log(error);
          });
    });
      
    });
    document.querySelectorAll(".intent").length === 4
      ? (count = document.querySelectorAll(".intent").length / 2)
      : (count = document.querySelectorAll(".intent").length);
    nCam = document.querySelectorAll(".intent").length;
  }, []);

 /*  useEffect(() => {
    //   initLocalMediaDevices();
    if (callingDialogVisible) {
     Audio?.current?.play();
    } else Audio?.current?.pause();
  }, [callingDialogVisible]); */
  useEffect(() => {
    //   initLocalMediaDevices();
    if (userCallingDialogVisible) {
    playing();
    } else stop();
  }, [userCallingDialogVisible]);
  
  const matches = useMediaQuery("(min-width:1480px)");
  console.log("width", Width.current ? Width.current.offsetWidth : 0);
  nCam = document.querySelectorAll(".intent").length;
  console.log("nCam", nCam);
  console.log(document.querySelectorAll(".intent").length);

  let size = Width.current ? Width.current.offsetWidth : 0;
  console.log("size", size, "count", count);




  const videoElm = document.querySelector('#localVideo');
  const btnFront = document.querySelector('#btn-front');
  const btnBack = document.querySelector('#btn-back');
  const [playing, { stop }] = useSound(Teams);
  const [ringing, { stopRing }] = useSound(Ring);

  const supports = navigator.mediaDevices.getSupportedConstraints();
  if (!supports['facingMode']) {
    alert('Browser Not supported!');
    return;
  }

  let stream;

  const capture = async facingMode => {
    const options = {
      audio: false,
      video: {
        facingMode,
      },
    };

    try {
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach(track => track.stop());
      }
      stream = await navigator.mediaDevices.getUserMedia(options);
    } catch (e) {
      alert(e);
      return;
    }
    videoElm.srcObject = null;
    videoElm.srcObject = stream;
    videoElm.pause();
videoElm.currentTime = 0;
var nopromise = {
   catch : new Function()
};
(videoElm.play() || nopromise).catch(function(){}); ;
   // videoElm.play();
  }

  if(btnBack){
    btnBack.addEventListener('click', () => {
      capture('environment');
    });
  }
if(btnFront){
  btnFront.addEventListener('click', () => {
    capture('user');
  });
}



  return (
    
    <div className="dashboard_container background_main_color">
     {loading ? ( < Default color="#0088FF" style={{position:"absolute",top:"50%",left:"50%",transform:"translate(-50%,-50%)"}}></Default>):<> 
     <Dialog
        open={userCallingDialogVisible }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div
            style={{
              display: " block",
              marginLeft: "auto",
              marginRight: "auto",
              borderRadius: "10px",
            }}
          >
            {" "}
            <br />
            <img
              style={{
                display: " block",
                marginLeft: "auto",
                marginRight: "auto",
                width: "20%",
                borderRadius: "10px",
              }}
              alt="img"
              src={callerPhoto || userAvatar}
            />
            <br />{" "}
          </div>
          {`Incoming Call from ${callerUsername} ...`}
        </DialogTitle>
        <DialogContent style={{ width: "400px", paddingBottom: "50px" }}>
          <div
            style={{
              display: "flex",
              textAlign: " center",
              justifyContent: "center",
            }}
          ></div>

          <div
            className={classe.btnDiv}
            style={{
              position: "absolute",
              left: "50%",
              top: "85%",
              transform: "translate(-50%,-50%)",
              paddingBottom: "30px",
            }}
          >
            <Button
              variant="contained"
              className={classe.answer}
              style={{ marginRight: "50px" }}
              // color="#29bb89"
              //  style={{ postion: "absolute", right: "20px" }}
              onClick={() => {
               //  Audio.current.pause();

                store.dispatch(setUserCallingDialogVisible(false));
                store.dispatch(setCallAccepted(true));
                //   setShowCallView(true);

                console.log("room number accept", roomNumber);
                socket.emit("create or join", roomNumber);
              }}
              //  tabIndex="0"
            >
              Answer
            </Button>
            <Button
              variant="contained"
              className={classe.decline}
              //   style={{ postion: "absolute", right: "20%" }}
              color="error"
              onClick={() => {
                setPlay(false)
                console.log('play2',playHere)
                store.dispatch(setUserCallingDialogVisible(false));
          //      setShowCallView(false);
             //   Audio.current.pause();
             
                console.log("roomNumber", roomNumber);
                console.log("visible", visible);
                socket.emit("decline-call", roomNumber);
                handleDeclineCall();
                //window.location.reload(false);
              }}
            >
              Decline
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={callingDialogVisible}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Incoming Call from  ${macAddress}  ...`}
        </DialogTitle>
        <DialogContent style={{ width: "400px" }}>
          <div
            style={{
              display: "flex",
              textAlign: " center",
              justifyContent: "center",
            }}
          ></div>

          <div className={classe.btnDiv}>
            <Button
              variant="contained"
              className={classe.answer}
              // color="#29bb89"
              style={{ postion: "absolute", right: "20px" }}
              onClick={() => {
        //        Audio.current.pause();
                store.dispatch(setCallingDialogVisible(false));
              
                //  setShowCallView(true);
                store.dispatch(setCallAccepted(true));

                socket.emit("create or join", room);
              }}
              //  tabIndex="0"
            >
              Answer
            </Button>
            <Button
              variant="contained"
              className={classe.decline}
              style={{ postion: "absolute", right: "-12px" }}
              color="error"
              onClick={() => {
                store.dispatch(setCallingDialogVisible(false));
                setShowCallView(false);
            //    Audio.current.pause();
                console.log("roomNumber", roomNumber);
                socket.emit("decline-call", roomNumber);
                socket.emit("leave-room", {
                  roomNumber: store.getState().call.room || roomNumber,
                  macAddress: macAddress,
                });
                handleDeclineCall();
                //  window.location.reload(false);
              }}
            >
              Decline
            </Button>
          </div>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      <div className="dashboard_left_section">
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={{ xs: 0 }}
            columns={{ xs: 4, sm: 8, md: 6.95 }}
          >
      {!showCallView &&    <Grid item xs={14}>
              <NavMenu socket={socket} matches={matches}></NavMenu>
            </Grid>}   
            {showDeviceDetail ? (
              <DeviceDetailComponent
                matches={matches}
                setShowDeviceDetail={setShowDeviceDetail}
              />
            ) : (
              <>
                {" "}
                {showCallView ? (
                  <div>
                    <>
                      {/* One or more Remote Camera */}
                      <Grid
                        container
                        style={{
                          width: matches && "86%",
                         // maxHeight: "86%",
                          position: "absolute",
                          left: matches
                            ? "40%"
                            : nCam === 4
                            ? "50%"
                            : nCam === 2
                            ? "50%"
                            : "50%",
                          transform: "translate(-50%,-50%)",
                          
                          zIndex: -1,
                        }}
                        spacing={0}
                      >
                        <Grid
                          item
                          xs={
                            nCam === 4 ? 2 : nCam === 2 ? 4 : nCam === 1 ? 8 : 8
                          }
                          sm={
                            nCam === 4 ? 4 : nCam === 2 ? 4 : nCam === 1 ? 9 : 4
                          }
                          md={
                            nCam === 4
                              ? 3.475
                              : nCam === 2
                              ? 3.475
                              : nCam === 1
                              ? 7
                              : 3.475
                          }
                        >
                          <video
                            className="intent"
                            id="remoteVideo"
                            ref={remote}
                            // src="https://media.istockphoto.com/videos/attractive-woman-blogger-speaks-about-professional-voice-over-and-video-id1253606799"
                            autoPlay
                            //muted={true}

                            style={{
                           
                            width: '120vw',
                            height: "100vh",
                            objectFit: "cover",
                            position: "fixed",
                            }}
                            playsInline
                            //  onClick={fullScreen}
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        width={"100%"}
                        height={"100%"}
                        ref={Width}
                        sx={{
                          position: "absolute",
                          left: "50%",
                          right: "50%",
                          top: "55%",
                          transform: "translate(-50%,-50%)",
                          zIndex: 0,
                        }}
                      >

                      </Grid>
                      <Container maxWidth={"xl"} style={{ zIndex: "1" }}>
                        <Grid
                          container
                          justifyContent={!matches ? "flex-end" : "flex-end"}
                          alignItems="space-around"
                          spacing={matches ? 5 : 4}
                          style={{
                            position: "absolute",
                            right: "1%",
                            display: "bloc",
                            marginTop: "66vh",
                            marginBottom: "auto",
                          }}
                        >
                          {/* <Grid  item xs={1} sm={1}  md={1}>
                      <video
                        // id="localVideo"
                        src="https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.mp4"
                        width={"100%"}
                        style={{
                       

                          zIndex: "1",
                        }}
                        autoPlay
                        muted={true}
                        playsInline
                      />
                      
          </Grid> 
           <Grid   item xs={1} sm={1}  md={1}>
                      <video
                        // id="localVideo"
                        src="https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.mp4"
                        width={"100%"}
                        style={{
                     

                          zIndex: "1",
                        }}
                        autoPlay
                        muted={true}
                        playsInline
                      />
                      
          </Grid> */}
                          
                          <Grid item xs={1} sm={1} md={1}>
                      {/*     <button style={{zIndex:"2",left:'25px'}} onClick={switchCamera}>Switch</button> */}
                   {/*        <button id="btn-front">Front</button>
                           <button id="btn-back">Back</button> */}
                            <video
                              id="localVideo"
                              ref={localVideo}
                              width={"90%"}
                              style={{
                                zIndex: "1",
                              }}
                              autoPlay
                              muted={true}

                              playsInline
                            />
                          </Grid>
                        </Grid>
                      </Container>
                      {/*one or more Local Camera  */}

                      <ConversationButtons
                        handleHangUpButtonPressed={handleHangUpButtonPressed}
                        socket={socket}
                        localCameraStream={localStream}
                        waiting={waiting}
                        matches={matches}
                        setWaiting={setWaiting}
                      />
                    </>
                  </div>
                ) : (
                  <>
                    <EventComponent matches={matches} />
                  </>
                )}{" "}
              </>
            )}
          </Grid>
        </Box>
      </div>

    {  !showCallView &&  <ProSideBarComponent
        setPlay={setPlay}
        play={playHere}
        showCallView={showCallView}
        setShowCallView={setShowCallView}
        socket={socket}
        setShowDeviceDetail={setShowDeviceDetail}
      />}
</>}
    </div>
  );
};
function mapStoreStateToProps({ call, data }) {
  return {
    ...call,
    ...data,
  };
}

export default connect(mapStoreStateToProps, {
  retrieveMaterialTypes,
  getInvitationsList,
})(CallView);
