export const callStates = {
  CALL_UNAVAILABLE: "CALL_UNAVAILABLE",
  CALL_AVAILABLE: "CALL_AVAILABLE",
  CALL_REQUESTED: "CALL_REQUESTED",
  CALL_IN_PROGRESS: "CALL_IN_PROGRESS",
};

export const CALL_SET_LOCAL_STREAM = "CALL.SET_LOCAL_STREAM";
export const CALL_SET_CALL_STATE = "CALL.SET_CALL_STATE";
export const CALL_SET_CALLING_DIALOG_VISIBLE ="CALL.SET_CALLING_DIALOG_VISIBLE";
export const CALL_SET_USER_CALLING_DIALOG_VISIBLE ="CALL.SET_USER_CALLING_DIALOG_VISIBLE";
export const CALL_SET_ROOM ="CALL.SET_ROOM";
export const CALL_SET_CALL_ACCEPTED ="CALL_SET_CALL_ACCEPTED";
export const CALL_SET_CALLER_USERNAME = "CALL.SET_CALLER_USERNAME";
export const CALL_SET_CALLER_EMAIL = "CALL.SET_CALLER_EMAIL";
export const CALL_SET_CALL_REJECTED = "CALL.SET_CALL_REJECTED";
export const CALL_SET_REMOTE_STREAM = "CALL.SET_REMOTE_STREAM";
export const CALL_SET_LOCAL_MICROPHONE_ENABLED ="CALL.SET_LOCAL_MICROPHONE_ENABLED";
export const CALL_SET_LOCAL_CAMERA_ENABLED = "CALL.SET_LOCAL_CAMERA_ENABLED";
export const CALL_SET_SCREEN_SHARING_ACTIVE = "CALL.SET_SCREEN_SHARING_ACTIVE";
export const CALL_RESET_CALL_STATE = "CALL.RESET_CALL_STATE";
export const CALL_SET_GROUP_CALL_ACTIVE = "CALL.CALL_SET_GROUP_CALL_ACTIVE";
export const CALL_SET_GROUP_CALL_STREAMS = "CALL.SET_GROUP_CALL_STREAMS";
export const CALL_CLEAR_GROUP_CALL_DATA = "CALL.CLEAR_GROUP_CALL_DATA";
export const CALL_SET_CHAT_MESSAGE = "CALL.SET_CHAT_MESSAGE";
export const CALL_SET_CALLER_ID = "CALL_SET_CALLER_ID";
export const CALL_SET_CALLEE_ID = "CALL_SET_CALLEE_ID";
export const CALL_SET_MAC_ADDRESS = "CALL_SET_MAC_ADDRESS";
export const CALL_SET_DATA_FOR_CALL = "CALL_SET_DATA_FOR_CALL" ;
/* export const RETRIEVE_TUTORIALS = "RETRIEVE_TUTORIALS"; */

export const setRoom = (room) => {
  return (dispatch) => {
    dispatch({
      type: CALL_SET_ROOM,
      room,
    });
  };
};
export const setDataForCall= (dataForCall) => {
  return (dispatch) => {
    dispatch({
      type: CALL_SET_DATA_FOR_CALL,
      dataForCall,
    });
  };
};
export const setMacAddress = (macAddress) => {
  return (dispatch) => {
    dispatch({
      type: CALL_SET_MAC_ADDRESS,
      macAddress,
    });
  };
};
export const setCallerID= (callerid) => {
  return (dispatch) => {
      dispatch({
  type: CALL_SET_CALLER_ID,
  callerid});
};};
export const setCalleeID = (calleeid) => {
  return (dispatch) => {
      dispatch({
  type: CALL_SET_CALLEE_ID,
  calleeid,
});
};
};

export const setLocalStream = (localStream) => {
  return (dispatch) => {
    dispatch({
      type: CALL_SET_LOCAL_STREAM,
      localStream,
    });
  };
};

export const setCallState = (callState) => {
  return (dispatch) => {
    dispatch({
      type: CALL_SET_CALL_STATE,
      callState,
    });
  };
};

export const setCallingDialogVisible = (visible) => {
    return (dispatch) => {
        dispatch({
    type: CALL_SET_CALLING_DIALOG_VISIBLE,
    visible,
});
};
};


export const setUserCallingDialogVisible = (visible) => {
  return (dispatch) => {
      dispatch({
  type: CALL_SET_USER_CALLING_DIALOG_VISIBLE,
  visible,
});
};
};

export const setCallAccepted = (accepted) => {
  return (dispatch) => {
      dispatch({
  type: CALL_SET_CALL_ACCEPTED,
  accepted,
});
};
};


export const setCallerUsername = (callerUsername) => {
    return (dispatch) => {
        dispatch({
    type: CALL_SET_CALLER_USERNAME,
    callerUsername,
  //  visible,
});
};
};

export const setCallerEmail = (callerEmail) => {
    return (dispatch) => {
        dispatch({
    type: CALL_SET_CALLER_EMAIL,
    callerEmail,
  //  visible,
});
};
};

export const setCallRejected = (callRejectedDetails) => {
    return (dispatch) => {
        dispatch({
    type: CALL_SET_CALL_REJECTED,
    callRejected: {
      rejected: callRejectedDetails.rejected,
      reason: callRejectedDetails.reason,
    }});
};
};
export const setRemoteStream = (remoteStream) => {
    return (dispatch) => {
        dispatch({
    type: CALL_SET_REMOTE_STREAM,
    remoteStream,
});
};
};
export const setLocalMicrophoneEnabled = (enabled) => {
    return (dispatch) => {
        dispatch({
    type: CALL_SET_LOCAL_MICROPHONE_ENABLED,
    enabled,
});
};
};

export const setLocalCameraEnabled = (enabled) => {
    return (dispatch) => {
        dispatch({
    type: CALL_SET_LOCAL_CAMERA_ENABLED,
    enabled,
});
};
};
export const setScreenSharingActive = (active) => {
    return (dispatch) => {
        dispatch({
    type: CALL_SET_SCREEN_SHARING_ACTIVE,
    active,
});
};
};
export const resetCallDataState = () => {
    return (dispatch) => {
        dispatch({
    type: CALL_RESET_CALL_STATE,
});
};
};


export const setMessage = (messageReceived, messageContent) => {
    return (dispatch) => {
        dispatch({
    type: CALL_SET_CHAT_MESSAGE,
    message: {
      received: messageReceived,
      content: messageContent,
    },
});
};
};
