import React from "react";
import {
  MdCallEnd,
  MdCall,
  MdMic,
  MdMicOff,
  MdVideocam,
  MdVideocamOff,
} from "react-icons/md";
import ConversationButton from "./ConversationButton";
import { connect } from "react-redux";
import "./ConversationButton.css";
import {
  /* setScreenSharingActive, */ setLocalMicrophoneEnabled,
  setLocalCameraEnabled,
} from "../../../store/actions/callActions";


//var  screenSharingStream;
const ConversationButtons = (props) => {
  const {
    waiting,
    localCameraStream,
    handleHangUpButtonPressed,
    setMicrophoneEnabled,
    setCameraEnabled,
    localMicrophoneEnabled,
    matches,
    localCameraEnabled,
  } = props;

  const handleMicButtonPressed = () => {
    let micEnabled = localCameraStream.getAudioTracks()[0].enabled;
    localCameraStream.getAudioTracks()[0].enabled = !micEnabled;
    setMicrophoneEnabled(!micEnabled);
    console.log(" Microphone", localMicrophoneEnabled);
  };

  const handleCameraButtonPressed = () => {
    let cameraEnabled = localCameraStream.getVideoTracks()[0].enabled;
    localCameraStream.getVideoTracks()[0].enabled = !cameraEnabled;
    console.log("localCameraEnabled", localCameraEnabled);

    setCameraEnabled(!cameraEnabled);
  };

  return (
    <>

        <div className={matches?"container":"sContainer"} /* style={styles.container} */>
          {" "}
          <div   className={matches?"btnContainer" :"sBtnContainer" }>
            <ConversationButton onClickHandler={handleMicButtonPressed}>
              {localMicrophoneEnabled ? (
                <MdMic className={matches?"icon" :"sIcon" }/>
              ) : (
                <MdMicOff className={matches?"icon" :"sIcon" } />
              )}
            </ConversationButton>
            {waiting ? (
              <ConversationButton onClickHandler={handleHangUpButtonPressed}> 
                <MdCall className={matches?"icon" :"sIcon" } />
              </ConversationButton>
            ) : (
              <ConversationButton onClickHandler={handleHangUpButtonPressed}>
                <MdCallEnd className={matches?"icon" :"sIcon" }/>
              </ConversationButton>
            )}
            <ConversationButton onClickHandler={handleCameraButtonPressed}>
              {localCameraEnabled ? (
                <MdVideocam className={matches?"icon" :"sIcon" } />
              ) : (
                <MdVideocamOff className={matches?"icon" :"sIcon" } />
              )}
            </ConversationButton>
          </div>
        </div>
    </>
  );
};

function mapStoreStateToProps({ call }) {
  return {
    ...call,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setCameraEnabled: (enabled) => dispatch(setLocalCameraEnabled(enabled)),
    setMicrophoneEnabled: (enabled) =>
      dispatch(setLocalMicrophoneEnabled(enabled)),
  };
}
export default connect(
  mapStoreStateToProps,
  mapDispatchToProps
)(ConversationButtons);
