import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enLocales from '../src/Language/en.json';
import deLocales from '../src/Language/de.json';
import frLocales from '../src/Language/fr.json';



i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
     resources: {
      en: { translation: enLocales },
      de: { translation: deLocales },
      fr: { translation: frLocales }
    },
   /*  lng: "en",
    fallbackLng: "fr", */
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });

export default i18n;
