import React, {  useEffect } from "react";
// @mui
import { styled } from "@mui/material/styles";
import { Box, Typography, Avatar } from "@mui/material";
// redux
import { connect } from "react-redux";

// components

// ----------------------------------------------------------------------

const InfoStyle = styled("div")(({ theme }) => ({
  left: 0,
  right: 0,
  zIndex: 99,
  position: "absolute",
  marginTop: theme.spacing(1),
  [theme.breakpoints.up("xs")]: {
    right: "auto",
    display: "flex",
    alignItems: "center",
    left: theme.spacing(1),
    bottom:theme.spacing(1.5),

  },
  [theme.breakpoints.up("md")]: {
    right: "auto",
    display: "flex",
    alignItems: "center",
    left: theme.spacing(4),
    bottom:theme.spacing(1.5),

  },
}));

// ----------------------------------------------------------------------

const DeviceCoverComponent = ({deviceDetails,macAddress}) =>{

  return (

    <InfoStyle>
  
      <Avatar
     src={deviceDetails &&  deviceDetails.materialType.image_url}  
        sx={{
          ml: { md: 1 },
          mt: { md: 0 },
          color: "common.white",
          textAlign:  "left" ,
          mx: "auto",
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: "common.white",
          width:  30 ,
          height:  30 ,
        }}
        alt="name"
      />
      <Box
        sx={{
          ml: { md: 1 },
          mt: {  md: 0 },
          color: "common.white",
          textAlign:   "left" ,
        }}
      >
        <Typography  sx={{
          mr: { md: 1 },
        }}variant="h8" style={{ color: "black" }}>
          {deviceDetails && deviceDetails.display_name} 
        </Typography><br/>
        <Typography variant="h8" sx={{ opacity: 0.72 }} style={{ color: "black" }}>
     {macAddress}
        </Typography>
      </Box>   
    </InfoStyle>

 
     )
      };

      function mapStoreStateToProps({ call, data }) {
        return {
          ...call,
          ...data,
        };
      }
      export default connect(mapStoreStateToProps, {
      
      })( DeviceCoverComponent);
