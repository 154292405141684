import React from "react";
import styled from "styled-components";
import appConfig from "../../../config/appConfig.json";
import { IoSearch } from "react-icons/io5";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion/dist/framer-motion";
import { useClickOutside } from "react-click-outside-hook";
import { useEffect } from "react";
import { useRef } from "react";
import userAvatar from "../../../resources/userAvatar.png";
import { useTranslation } from "react-i18next";
import "./index.css";
//import MoonLoader from "react-spinners/MoonLoader";
import { useDebounce } from "../hooks/debounceHook";
import axios from "axios";
import PersonRemoveAlt1Icon from "@mui/icons-material/PersonRemoveAlt1";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { getSentInvitations, searchFriend } from "../../../store/actions/dataActions";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import { inviteUser } from "../../../store/actions/dataActions";
import Axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
const SearchBarContainer = styled(motion.div)`
  position: relative;
  width: 100%;
  border-radius: 6px;
  z-index: 1;
`;

const SearchInputContainer = styled.div`
  width: 100%;
  position: absolute;
  min-height: 2em;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0px 15px;
`;

const SearchInput = styled.input`
  width: 70%;
  height: 100%;
  position:absolute;
  outline: none;
  border: none;
  font-size: 16px;
  color: black;
  padding-left:40px;
  font-weight: 500;
  border-radius: 6px;
  background-color: transparent;
  z-index:1
  &:focus {
    outline: none;
    &::placeholder {
      opacity: 0;
    }
  }

  &::placeholder {
    color: #666565;
    transition: all 250ms ease-in-out;
    z-index:1
  }
`;

const SearchIcon = styled.span`
  color: #666565;
  font-size: 20px;
  position: absolute;
  margin-right: 20px;
  margin-top: 2px;
  vertical-align: middle;
  z-index: 1;
`;

const LineSeperator = styled.span`
  display: flex;
  position: absolute;
  min-width: 100%;
  min-height: 2px;
  background-color: #bdbdbd;
  z-index: 1;
`;

const SearchContent = styled.div`
  width: 100%;
  height: 100%;
  position:absolute;
  display: flex;
  flex-direction: column;
  padding-top: 1em;
  overflow-y: auto;
  max-width: 100%;
  overflow-x: hidden;
  align-items: stretch;
    white-space:normal;
    justify-content: center
  z-index:1;
  div:last-child {
    margin-bottom: 50px;
  }
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const WarningMessage = styled.span`
  color: #a1a1a1;
  font-size: 14px;
  display: flex;
  position: absolute;
  //left: 14%;
  top: 30%;
  align-self: center;
  justify-self: center;
  z-index: 1;
`;

const TvShowContainer = styled.div`
  width: 100%;
  min-height: 2em;
  display: flex;
  border-bottom: 2px solid #d8d8d852;
  padding: 6px 8px;
  align-items: center;
`;

const Thumbnail = styled.div`
  width: auto;
  height: 100%;
  max-height: 40px;
  display: flex;
  flex: 0.4;
`;

const Name = styled.p`
  font-size: 15px;
  color: black;
  margin-left: 10px;
  flex: 2;
  display: flex;
`;

const Rating = styled.span`
  color: #a1a1a1;
  font-size: 16px;
  display: flex;
  flex: 0.2;
  margin-right: 15%;
`;

const containerVariants = {
  expanded: {
    height: "10em",
  },
  collapsed: {
    height: "1.8em",
  },
};

const containerTransition = { type: "spring", damping: 22, stiffness: 150 };

const SearchBar = ({
  inviteUser,
  user,
  searchFriend,
  friends,
  handleClose,
  setFriendsData,setSentInv,getSentInvitations,
}) => {
  const [isExpanded, setExpanded] = useState(false);
  const [parentRef, isClickedOutside] = useClickOutside();
  const inputRef = useRef();
  const [searchQuery, setSearchQuery] = useState("");
  const [receiverEmail, setReceiverEmail] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [nouserList, setNouserList] = useState(false);
  const isEmpty = !userList || userList.length === 0;
  const { t } = useTranslation();
  useEffect(() => {
    searchFriend(searchQuery);
  }, [searchQuery]);

  const sendInvitation = (receiverEmail) => {
    console.log("clicked", receiverEmail);
    setReceiverEmail(receiverEmail);
    inviteUser(receiverEmail);

    handleClose();
    setTimeout(function () {
      getSentInvitations()
    }, 1000);
    
  };

  const removeInvitation = (receiverEmail) => {
    handleDeleteInvitation(receiverEmail);
  };
  const handleDeleteFriend = (email_user) => {
    console.log("email_user", email_user);
    let emailFriend = localStorage.getItem("email");
    Swal.fire({
      title: t("Are you sure?"),
      text: t("You won't be able to revert this!"),
      icon: t("warning"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "none",
      cancelButtonText: t("Close"),
      confirmButtonText: t("Yes, delete it!"),
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("email_user", email_user);

        axios.delete(appConfig.baseUrl + "/user/api/removefriendwithEmail", {
          data: { emailUser: email_user, emailFriend: emailFriend },
        });

        Swal.fire(
          t("Deleted!"),
          t("Your friend has been deleted."),
          t("success")
        ).then(   Axios.post(appConfig.baseUrl + "/user/api/getmyfriendlists", {
          idUser: localStorage.getItem("id"),
        })
          .then((response) => {
            setFriendsData(response.data);
            console.log("friendsList", response.data);
            response.data.map((aFriend) => {
              console.log(
                "aFriend.userSockets",
                aFriend.user_sockets[0] !== undefined
              );
            });
          })
          .catch((error) => {
            console.log(error);
          })
          )
      }
    });
  };

  const handleDeleteInvitation = (email_user) => {
    Swal.fire({
      title: t("Are you sure?"),
      text: t("You won't be able to revert this!"),
      icon: t("warning"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Yes, delete it!"),
      customClass: "zIndex:30",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("email_user", email_user);
        console.log("id", localStorage.getItem("id"));

        let res = axios.post(appConfig.baseUrl + "/user/api/removeinvitation", {
          aUserId: localStorage.getItem("id"),
          anInvitedFriendEmail: email_user,
        });
        console.log("res", res.data);

        Swal.fire(
          t("Deleted!"),
          t("Your invitation has been deleted."),
          t("success")
        ).then(() => window.location.reload(false));
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Swal.fire(t("Cancelled"));
      }
    });
  };

  const changeHandler = (e) => {
    e.preventDefault();
    if (e.target.value.trim() === "") setNouserList(false);

    setSearchQuery(e.target.value);
  };

  const expandContainer = () => {
    setExpanded(true);
  };

  const collapseContainer = () => {
    setExpanded(false);
    setSearchQuery("");
    setLoading(false);
    setNouserList(false);
    setUserList([]);
    if (inputRef.current) inputRef.current.value = "";
  };

  useEffect(() => {
    if (isClickedOutside) collapseContainer();
  }, [isClickedOutside]);

  const searchuserList = async () => {
    if (!searchQuery || searchQuery.trim() === "") return;

    setLoading(true);
    setNouserList(false);

    console.log("SearchQuery", searchQuery);

    const response = await axios.post(
      appConfig.baseUrl + "/user/api/searchFriends",
      {
        friend: searchQuery,
        idUser: localStorage.getItem("id"),
      }
    );

    if (response) {
      if (response.data && response.data.length === 0) setNouserList(true);

      setUserList(response.data.friends);
      console.log("show", response.data.friends);
    }

    setLoading(false);
  };

  useDebounce(searchQuery, 500, searchuserList);

  return (
    <SearchBarContainer
      animate={isExpanded ? "expanded" : "collapsed"}
      variants={containerVariants}
      transition={containerTransition}
      ref={parentRef}
    >
      <SearchInputContainer>
        <SearchIcon>
          <IoSearch />
        </SearchIcon>
        <SearchInput
          placeholder={t("Search")}
          onFocus={expandContainer}
          ref={inputRef}
          value={searchQuery}
          onChange={changeHandler}
        />
        {/*       <AnimatePresence>
          {isExpanded && (
            <CloseIcon
              key="close-icon"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={collapseContainer}
              transition={{ duration: 0.2 }}
            >
              <IoClose />
            </CloseIcon>
          )}
        </AnimatePresence> */}
      </SearchInputContainer>
      {isExpanded && <LineSeperator />}
      {isExpanded && (
        <SearchContent>
          {/*     {isLoading && (
            <LoadingWrapper>
              <MoonLoader loading color="#000" size={20} />
            </LoadingWrapper>
          )} */}
          {!isLoading && isEmpty && !nouserList && (
            <LoadingWrapper>
              <WarningMessage>{t("Start typing to Search")}</WarningMessage>
            </LoadingWrapper>
          )}
          {!isLoading && nouserList && (
            <LoadingWrapper>
              <WarningMessage>No Tv Shows or Series found!</WarningMessage>
            </LoadingWrapper>
          )}
          {!isLoading && !isEmpty && (
            <>
              {userList.map((show) => (
                <TvShowContainer key={show.id}>
                  <Thumbnail
                    style={{
                      position: "relative",
                      left: "15%",
                      transform: "translateX(-50%)",
                    }}
                  >
                    <img
                      style={{
                        width: "30px",
                        height: "30px",
                        float: "left ",
                        position: "absolute",
                        /*  top:"30px" */ top: "50%",
                        transform: "translate(0%,-50%)",
                        borderRadius: "50%",
                      }}
                      alt="img"
                      src={show.photo_url || userAvatar}
                    />
                  </Thumbnail>

                  <Name
                    style={{
                      position: "relative",
                      width: "20px",
                      left: "50%",
                      transform: "translateX(-50%)",
                    }}
                  >
                    {show.display_name}
                  </Name>
                  {show.isFriend === false && show.alreadyInvited === false && (
                    <Rating>
                      <Tooltip
                        followCursor
                        arrow
                        title={t("Invite User")}
                        style={{ zIndex: 25 }}
                      >
                        <IconButton>
                          <PersonAddAlt1Icon
                            style={{ zIndex: 20 }}
                            onClick={() => {
                              sendInvitation(show.email);
                              console.log("sended");
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Rating>
                  )}

                  {show.isFriend === true && (
                    <Rating>
                      <Tooltip
                        followCursor
                        arrow
                        title={t("Remove Friend")}
                        style={{ zIndex: 25 }}
                      >
                        <IconButton>
                          <PersonRemoveAlt1Icon
                            style={{ zIndex: 20 }}
                            onClick={() => {
                              handleClose();
                              handleDeleteFriend(show.email);
                            }}
                          />{" "}
                        </IconButton>
                      </Tooltip>
                    </Rating>
                  )}
                  {show.alreadyInvited === true && (
                    <Rating>
                      <Tooltip
                        arrow
                        followCursor
                        title={t("Remove Invitation")}
                        style={{ zIndex: 25 }}
                      >
                        <IconButton>
                          <PeopleAltIcon
                            style={{ zIndex: 20 }}
                            onClick={() => {
                              handleClose();
                              removeInvitation(show.email);
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Rating>
                  )}
                </TvShowContainer>
              ))}
            </>
          )}
        </SearchContent>
      )}
    </SearchBarContainer>
  );
};

function mapStoreStateToProps({ call, data }) {
  return {
    ...call,
    ...data,
  };
}
export default connect(mapStoreStateToProps, { searchFriend, inviteUser })(
  SearchBar
);
