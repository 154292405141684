import React, { useState, useEffect } from "react";
import appConfig from "../../../config/appConfig.json";
import "./custom.scss";
import { connect } from "react-redux";
import userAvatar from "../../../resources/userAvatar.png";
import Axios from "axios";
import store from "../../../store/store";
import { useTranslation } from 'react-i18next';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarFooter,
} from "react-pro-sidebar";
import { setRoom,setCallerID,setCalleeID,setDataForCall } from "../../../store/actions/callActions";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import FormControl from "@mui/material/FormControl";
import ListIcon from "@mui/icons-material/List";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import Logo_Avempace from "../../../resources/Logo_Avempace_2.png";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { MdLocalPhone } from "react-icons/md";
import useSound from 'use-sound';
import SidebarOutsiderAlerter from "../OutsiderAlerter/SidebarOutsiderAlerter"
//redux
import {
  retrieveMaterialTypes,
  getSentInvitations,
  retrieveDeviceName,
  retrieveDeviceData,
getMyFriendsList,
} from "../../../store/actions/dataActions";
import { setMacAddress} from "../../../store/actions/callActions";
//sweetAlert
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

import "./ProSideBar.css";
import Ring from "../../../resources/ringing.wav"
//@mui
import { styled } from "@mui/material/styles";
import { BsArrowLeftSquareFill } from "react-icons/bs";
import { BsFillArrowRightSquareFill } from "react-icons/bs";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SearchBar from "../searchBar/index";
import { v4 as uuidv4 } from "uuid";
import useMediaQuery from "@mui/material/useMediaQuery";
let uid = uuidv4();
console.log("uid", uid);
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ProSideBarComponent = ({
  socket,
  retrieveMaterialTypes,
  retrieveDeviceName,
  retrieveDeviceData,
  sentInvitations,
  getSentInvitations,
  room,
  getmyfriendlists,
  myFriendsList,
  macAddress,
  getMyFriendsList,
  setPlay,showCallView,
  setShowCallView,callAccepted,setShowDeviceDetail,deviceDetails,deviceRecordings
}) => {
  const [deviceData, setDeviceData] = useState([]);
  const [friendsData, setFriendsData] = useState([]);
  const [sentInv, setSentInv] = useState([]);
  const [mac_address, setMac_address] = useState("");
  const [ringing, { stopRing }] = useSound(Ring);
  const [deviceMacAddress, setDeviceMacAddress] = useState(null);
  const [friend_firstname, setFriend_firstname] = useState("");
  const [friend_lastname, setFriend_lastname] = useState("");
  const [user_email, setUser_email] = useState(null);
console.log('showCallView',showCallView)
  const { t } = useTranslation();
 /*  useEffect(() => {
    //   initLocalMediaDevices();
    if (showCallView) {
    ringing();
    } else stopRing();
  }, [showCallView]); */
  console.log("get sent invitations here",sentInvitations)
  useEffect(() => {
    socket.on("user_devices_connection_changed", function (data) {
      console.log("user_devices_connection_changed: " + JSON.stringify(data));
      let userId = localStorage.getItem("id");
      Axios.post(appConfig.baseUrl + "/device/getUserDevices/", {
        userId: userId,
      })
        .then((response) => {
          console.log("deviceData", response.data.devices);
          setDeviceData(response.data.devices);
        })
        .catch((error) => {
          console.log(error);
        });
    });
    socket.on("invitation-removed",function(data){
      console.log("invitation removed")
      getSentInvitations()
   /*    Axios.post(appConfig.baseUrl + "/user/api/getSentInvitation", {
        aUserId: localStorage.getItem("id"),
      })
        .then((response) => {
          setSentInv(response.data);
        })
        .catch((error) => {
          console.log(error);
        }); */
    })
    socket.on("friend_connection_changed", function (data) {
      console.log("friend_connection_changed: " + JSON.stringify(data));
      
      getMyFriendsList()
  
    });
  }, []);


  useEffect(() => {
    let userId = localStorage.getItem("id");
    getMyFriendsList();
    retrieveMaterialTypes();

    Axios.post(appConfig.baseUrl + "/device/getUserDevices/", {
      userId: userId,
    })
      .then((response) => {
        console.log("deviceData", response.data.devices);
        setDeviceData(response.data.devices);
      })
      .catch((error) => {
        console.log(error);
      });
     
      getSentInvitations()
  
 /*   Axios.post(appConfig.baseUrl + "/user/api/getSentInvitation", {
      aUserId: localStorage.getItem("id"),
    })
      .then((response) => {
        console.log("getSentInvitation not redux",response.data);
        setSentInv(response.data);
      })
      .catch((error) => {
        console.log(error);
      });  */
  }, []);


  const useStyles = makeStyles({
    textInputWrapper: {
      marginTop: "1em",
      marginBottom: "1em",
    },
    input: {
      "&::placeholder": {
        fontWeight: "bold",
        fontStyle: "italic",
      },
    },
    root: {
      "&:not(.Mui-disabled):hover::after": {
        borderColor: "#1D1D1D",
      },
      "&:not(.Mui-disabled):hover::before": {
        borderColor: "#1D1D1D",
      },
      "&:not(.Mui-disabled):focus::after": {
        borderColor: "#1D1D1D",
      },
      "&:not(.Mui-disabled):focus::before": {
        borderColor: "#1D1D1D",
      },
    },
    inputRoot: {
      color: "purple",
      // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
      '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        // Default left padding is 6px
        paddingLeft: 26,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "green",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "red",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "purple",
      },
    },
  });
  const classes = useStyles();

  const handleClick = (mac_address) => {
    console.log("clicked", mac_address);
    setDeviceMacAddress(mac_address);
  };
  const handleClickFriend = (user_email) => {
    console.log("clicked user_email", user_email);
    setUser_email(user_email);
  };

  const handlefriendInfo = () => {
    setFriend_firstname(friend_firstname);
    setFriend_lastname(friend_lastname);
  };

  const handleDeleteInvitation = (email_user) => {
    Swal.fire({
      title: t("Are you sure?"),
      text: t("You won't be able to revert this!"),
      icon: t("warning"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "none",
      cancelButtonText:t("Close"),
      confirmButtonText: t("Yes, delete it!"),
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("email_user", email_user);
        console.log("id", localStorage.getItem("id"));

        let res = Axios.post(appConfig.baseUrl + "/user/api/removeinvitation", {
          aUserId: localStorage.getItem("id"),
          anInvitedFriendEmail: email_user,
        });
        console.log("res", res.data);

        Swal.fire(
          t("Deleted!"),
         t( "Your invitation has been deleted."),
          t("success")
        ).then(
         
          Axios.post(appConfig.baseUrl + "/user/api/getSentInvitation", {
            aUserId: localStorage.getItem("id"),
          })
            .then((response) => {
              setSentInv(response.data);
            })
            .catch((error) => {
              console.log(error);
            })
          //() => window.location.reload(false)
          );
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Swal.fire(t("Cancelled"));
      }
    });
  };

  const handleDeleteFriend = (email_user) => {
    console.log("email_user", email_user);
    let emailFriend = localStorage.getItem("email");
    Swal.fire({
      title: t("Are you sure?"),
      text: t("You won't be able to revert this!"),
      icon: t("warning"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonText:t("Close"),
      cancelButtonColor: "none",
      confirmButtonText: t("Yes, delete it!"),
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("email_user", email_user);

        Axios.delete(appConfig.baseUrl + "/user/api/removefriendwithEmail", {
          data: { emailUser: email_user, emailFriend: emailFriend },
        });

        Swal.fire(t("Deleted!"), t("Your friend has been deleted."), t("success")).then(
         () => {
          getMyFriendsList()
        }
        );
      }
    });
  };

  const handleDeleteDevice = (mac_address) => {
    Swal.fire({
      title: t("Are you sure?"),
      text: t("You won't be able to revert this!"),
      icon: t("warning"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "none",
      cancelButtonText:t("Close"),
      confirmButtonText: t("Yes, delete it!"),
    }).then((result) => {
      if (result.isConfirmed) {
        let macaddress = deviceMacAddress;
        Axios.post(appConfig.baseUrl + "/device/unlinkDeviceFromUser/", {
          userId: localStorage.getItem("id"),
          macAddress: mac_address,
        }).then((res)=>{
          Axios.post(appConfig.baseUrl + "/device/getUserDevices/", {
            userId: localStorage.getItem("id"),
          })
            .then((response) => {
              console.log("deviceData", response.data.devices);
              setDeviceData(response.data.devices);
            })
            .catch((error) => {
              console.log(error);
            });
        });
        
        
        Swal.fire(t("Deleted!"), t("Your Device has been deleted."), t("success")).then(
          Axios.post(appConfig.baseUrl + "/device/getUserDevices/", {
            userId: localStorage.getItem("id"),
          })
            .then((response) => {
              console.log("deviceData", response.data.devices);
              setDeviceData(response.data.devices);
            })
            .catch((error) => {
              console.log(error);
            })
        );
      }
    });
  };
  const Pair = () => {
    Axios.post(appConfig.baseUrl + "/device/requestPairingDevice/", {
      idUser: localStorage.getItem("id"),
      macAddress: mac_address,
    }).then((response) => {
      console.log("request pairing response", response.data);
      if (response.data.success) {
        Swal.fire({
          title: "Enter Code",
          input: "text",
          /*     html: `<input type="text" id="input" class="swal2-input" placeholder="Pairing code">`, */
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: t("Pair"),
          showLoaderOnConfirm: true,
          preConfirm: () => {
            console.log("pairingCode", Swal.getInput().value);

            return Axios.post(
              appConfig.baseUrl + "/device/requestValidatePairingCode/",
              {
                userId: localStorage.getItem("id"),
                macAddress: mac_address,
                pairingCode: Swal.getInput().value,
              }
            )
              .then((res) => {
                console.log("responseConfirm", res.data);
                if (!res.data.success) {
                  throw new Error(res.statusText);
                } else {
                  if (res.data.success) {
                    Axios.post(appConfig.baseUrl + "/device/getUserDevices/", {
                      userId: localStorage.getItem("id"),
                    })
                      .then((response) => {
                        console.log("deviceData", response.data.devices);
                        setDeviceData(response.data.devices);
                      })
                      .catch((error) => {
                        console.log(error);
                      })
                  }
                }
              })
              .catch((error) => {
                Swal.showValidationMessage(`Request failed: ${error}`);
              });
          },
          allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload(false);
            Swal.fire({
              title: t(`Device added successfully`),
            }).then(() => window.location.reload(false));
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: t("Something went wrong!"),
              html: t("Cannot add device") + "<br/>" + t("Please try again"),
            });
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: t("Something went wrong!"),
          html:
            " <b>"+t('Pairing Request Failed')+"</b> " +
            "<br/>" +
            "<br/>" +
            t("Please try again"),

          //footer: '<a href="">Why do I have this issue?</a>'
        });
      }
    });
  };

  const callUserFriend = (calleeId) => {
   ringing()
    setShowDeviceDetail(false);
    setShowCallView(true);
    Axios.post(appConfig.baseUrl + "/user/api/callUserFromUser", {
      callerId: localStorage.getItem("id"),
      calleeId: calleeId,
    })
      .then((response) => {
        if(response.data.success){
        store.dispatch(setCalleeID( response.data.roomInfo.theCalleeId ));
        store.dispatch(setCallerID( response.data.roomInfo.theCallerId ));
        console.log("callUser RESPONSE: " + JSON.stringify(response.data));
        var theRoomNumber = response.data.roomInfo.roomNumber;
        console.log("callUser RESPONSE  theRoomNumber" + theRoomNumber);
        console.log("room call user: " + theRoomNumber);
        socket.emit("create or join", theRoomNumber);
        store.dispatch(setRoom(theRoomNumber));
        store.dispatch(setDataForCall(response.data))
        Swal.closeModal();}
        else{
          Swal.closeModal();
          Swal.fire({
            icon: 'error',
            text: 'Cannot call user',
        
            confirmButtonColor:"#0088FF"
          })
          //popup error message

        }
      })
      .catch((error) => {
        console.log("callUser ERROR " + error);
        Swal.closeModal();
      });
  };
  const CallFriend = (friend_name, friend_lastname, friend_id) => {
    console.log("roomCallFriend", room);

    Swal.fire({
      title: t("Do you really want to call?"),
      width: '400px',
      html: `   <style>
      span {

        font-size:15px
    
      }
    </style> ${friend_name} ${friend_lastname} `,

      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "none",
      cancelButtonText:t("Cancel"),
      confirmButtonText: t("Yes"),
    }).then((result) => {
      if (result.isConfirmed) {
        callUserFriend(friend_id);
        
        Swal.fire({
          title: t("Calling..."),
          text: t("Waiting for answer"),
          iconHtml: " <style> h4 {color:green}</style><h4 >✆</h4>",
          showConfirmButton: false,
          cancelButtonText: t("Cancel"),
          cancelButtonColor: "none",
          showCancelButton: true,
          showCloseButton: true,
        });
      }
    });
  };

  const CallDevice = (mac_address, device_name) => {
    Swal.fire({
      title: t("Do you really want to call?"),

      html: `   <style>
      span {

        font-size:15px
    
      }
    </style> ${device_name} <br> <span>${mac_address}</span> `,

      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "none",
      cancelButtonText:t("Cancel"),
      confirmButtonText: t("Yes"),
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("mac_address", mac_address);

        callUserDevice(mac_address);

        Swal.fire({
          title: t("Calling..."),
          text: t("Waiting for answer"),
          iconHtml: " <style> h4 {color:green}</style><h4 >✆</h4>",
          showConfirmButton: false,
          showCancelButton: false,
          showCloseButton: true,
        });
      }
    });
  };
  const callUserDevice = (aMacAddress) => {
    Axios.post(appConfig.baseUrl + "/user/api/callDevice/", {
      userId: localStorage.getItem("id"),
      macAddress: aMacAddress,
    })
      .then((response) => {
        console.log("callDevice RESPONSE " + JSON.stringify(response.data));

        Swal.closeModal();
      })
      .catch((error) => {
        console.log("callDevice ERROR " + error);
        Swal.closeModal();
      });
  };


  const onChange = (event) => {
    event.preventDefault();
    setMac_address(event.target.value);
  };

  const [open, setOpen] = useState(false);
  const [openDevice, setOpenDevice] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenDevice = () => {
    setOpenDevice(true);
  };
  const handleCloseDevice = () => {
    setOpenDevice(false);
  };

  const [toggle, setToggle] = useState("hidden");

  const hideSidebar = () => {
    setToggle(!toggle);
  };
  const showSidebar = () => {
    setToggle("hidden");
  };
  const matches = useMediaQuery("(min-width:1480px)");
  return (
    <>
     

      {toggle === "hidden" ? (
        <Button
          onClick={hideSidebar}
          style={{
            position: "absolute",
            zIndex: 1,
            top: "56px",
            left: "-15px",
            color: "#0088FF",
           
          }}
        >
          {!matches && <BsFillArrowRightSquareFill size={20} />}
        </Button>
      ) : (
        <Button
          onClick={showSidebar}
          style={{
            position: "absolute",
            zIndex: 1,
            top: "56px",
            left: "228px",
            color: "#0088FF",
          }}
        >
          {!matches && <BsArrowLeftSquareFill size={20} />}
        </Button>
      )}
      <SidebarOutsiderAlerter setToggle={setToggle} >   <ProSidebar
        breakPoint="lg"
        className="pro-sidebar"
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          left: "0",
          position: "absolute",
          zIndex: 0,
          visibility: !matches && toggle,
        }}
      >
        <Menu iconShape="square">
          <BootstrapDialog
            onClose={handleCloseDevice}
            aria-labelledby="customized-dialog-title"
            open={openDevice}
            fullWidth
          >
             <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleCloseDevice}
            >
              {t('Add Device')}
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <FormControl variant="standard">
                <InputLabel
                  htmlFor="input-with-icon-adornment"
                  style={{ color: "black" }}
                >
                  {t('Enter Device MAC Address')}
                </InputLabel>
                <Input
                  id="input-with-icon-adornment"
                  onChange={onChange}
                  className={(classes.textInputWrapper, classes.root)}
                  classes={{ input: classes.input }}
                  style={{ width: "149%", color: "black" }}
                  startAdornment={
                    <InputAdornment position="start"></InputAdornment>
                  }
                ></Input>
              </FormControl>
              <br /> <br />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => {
                  handleCloseDevice();
                  Pair();
                }}
              >
               {t('Pair')}
              </Button>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose}>
                {t('Close')}
              </Button>
            </DialogActions>
          </BootstrapDialog>
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            fullWidth
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleClose}
            >
             { t('Search User')}
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <SearchBar getSentInvitations={getSentInvitations} sentInvitations= {sentInvitations} setSentInv={setSentInv} setFriendsData={setFriendsData} handleClose={handleClose} />
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleCloseDevice}>
               { t('Close')}
              </Button>
            </DialogActions>
   
          </BootstrapDialog>
{/* 
          <BootstrapDialog
            onClose={handleCloseDevice}
            aria-labelledby="customized-dialog-title"
            open={openDevice}
            fullWidth
            style={{ maxWidth: "450px", paddingLeft: "35%" }}
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleCloseDevice}
            >
              {t('Add Device')}
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <FormControl variant="standard">
                <InputLabel
                  htmlFor="input-with-icon-adornment"
                  style={{ color: "black" }}
                >
                  {t('Enter Device MAC Address')}
                </InputLabel>
                <Input
                  id="input-with-icon-adornment"
                  onChange={onChange}
                  className={(classes.textInputWrapper, classes.root)}
                  classes={{ input: classes.input }}
                  style={{ width: "149%", color: "black" }}
                  startAdornment={
                    <InputAdornment position="start"></InputAdornment>
                  }
                ></Input>
              </FormControl>
              <br /> <br />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => {
                  handleCloseDevice();
                  Pair();
                }}
              >
               {t('Pair')}
              </Button>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleCloseDevice}>
               { t('Close')}
              </Button>
            </DialogActions>
          </BootstrapDialog> */}
          <MenuItem
           onClick={() =>window.location.reload(false)}
            style={{
              height: "50px",
              color: "#FEFEFE",
              backgroundColor: "#0088ff",
              marginTop: "15px",
              width: "90%",
              transform: "translate(5%, -0)",
            }}
           
          >
            <p
              style={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                
                }}
              >
                <ListIcon />
              </span>
              <span style={{ marginBottom: "17px", marginLeft: "40px"}}>
              { t('Events')}
              </span>
              
            </p>
          </MenuItem>
          <MenuItem
            style={{
              height: "50px",
              color: "#FEFEFE",
              backgroundColor: "#0088ff",
              marginTop: "15px",
              width: "90%",
              transform: "translate(5%, -0)",
            }}
          >
           <p
              style={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
             <span
                style={{
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                
                }}
              >
                <ListIcon />
              </span>
              <span style={{ marginBottom: "17px", marginLeft: "40px" }}>
              { t('Friends List')}
              </span>
              <span
                style={{
               
                  position: "absolute",
                  top: "50%",
                  left: matches?"195px":"190px",
                  transform: "translateY(-50%)",
              
                
                }}
              >
                <PersonAddOutlinedIcon id="icon" 
                  fontSize="small"
                  onClick={handleClickOpen}
                />
              </span>
            </p>
          </MenuItem>

          {myFriendsList.map((aFriend) => (
            <MenuItem
            id="menu-item"
              key={aFriend.id}
              style={{
              // color: "black",
             // backgroundColor: "#EFEFEF",
                // borderRadius: "30px",
                transform: "translate(10%,-0px)",
                marginTop: "10px",
                width: "85%",
              }}
            >
              <img
                
                style={{
                  width: "30px",
                  height: "30px",
                  float: "left ",
                  position: "absolute",
                  /*  top:"30px" */ top: "50%",
                  transform: "translate(0%,-50%)",
                  borderRadius: "50%",
                }}
                alt="img"
                align="left"
                src={aFriend.photo_url || userAvatar}
              />
              <p
                style={{
                  position: "relative",
                  width: "60%",
                  fontSize: "15px",
                  color: "black",
                  display: "flex",
                  left: "55%",
                  transform: "translate(-50%,0)",
                  whiteSpace: "break-spaces",
                }}
              >
                {aFriend.firstName} {aFriend.lastName}
               
              </p>
              <div className="icons">
                {aFriend && aFriend.user_sockets !== 0 ? (
                  <span
                    style={{
                      position: "absolute",
                      left: "8%",
                      color: "#00F700",
                      //bottom: "50%",
                      top: "50%",
                      transform: "translate(0%,0%)",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      fill="currentColor"
                      className="bi bi-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <circle cx="8" cy="8" r="8" />
                    </svg>
                  </span>
                ) : (
                  <span
                    style={{
                      position: "absolute",
                      left: "8%",
                      color: "#F70000",
                      //bottom: "50%",
                      top: "50%",
                      transform: "translate(0%,0%)",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      fill="currentColor"
                      className="bi bi-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <circle cx="8" cy="8" r="8" />
                    </svg>
                  </span>
                )}
                <span
                id="iconFill"
                  style={{
                    position: "absolute",
                    right: "20%",
                    //color: "grey",
                    //bottom: "46%",
                    top: "50%",
                    transform: "translate(0,-55%)",
                  }}
                  onClick={() => {
                    handlefriendInfo(aFriend.firstName, aFriend.lastName);
                    console.log("sockets",aFriend.user_sockets)
                   if(aFriend.user_sockets){CallFriend(aFriend.firstName, aFriend.lastName, aFriend.id);} 
                   else{Swal.fire({
                    icon: 'error',
                    text: t("Please verify your friend connection"),
                    title: "",
                  
                    confirmButtonColor:"#0088FF"
                  })}
                  }}
                >
                  <MdLocalPhone  />
                </span>

                <span
                 id="iconFill"
                  style={{
                    position: "absolute",
                    right: "8%",
                   // color: "grey",
                    // bottom: "44%",
                    top: "50%",
                    transform: "translate(0,-55%)",
                  }}
                  onClick={() => {
                    handleClickFriend(aFriend.email);
                    handleDeleteFriend(aFriend.email);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-trash"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path
                      fillRule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    />
                  </svg>
                </span>
              </div>
            </MenuItem>
          ))}
          <MenuItem
          
          style={{
            height: "50px",
            color: "#FEFEFE",
            backgroundColor: "#0088ff",
            marginTop: "15px",
            width: "90%",
            transform: "translate(5%, -0)",
          }}
          >
            <p
              style={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                
                }}
              >
                <ListIcon />
              </span>
              <span style={{ marginBottom: "17px", marginLeft: "40px" }}>
              { t('Devices List')}
              </span>
              <span
                style={{
               
                  position: "absolute",
                  top: "50%",
                  left: matches?"195px":"190px",
                  transform: "translateY(-50%)",
                }}
              >
                <AiOutlineAppstoreAdd onClick={handleClickOpenDevice} id="icon"/>
              </span>
            </p>
          </MenuItem>

          {deviceData.map((device) => (
            <MenuItem
            id="menu-item"
              key={device.id}
             /*  onClick={()=>{
                retrieveDeviceData(device.mac_address);
                if(deviceDetails){
                setShowDeviceDetail(true);}
             }} */
              style={{
         
               // color: "black",
               // backgroundColor: "#EFEFEF",
                //borderRadius: "30px",
                transform: "translate(10%,-0px)",
                marginTop: "10px",
                width: "85%",
              }}
            ><div style={{  width: "85%"}}  onClick={()=>{
              store.dispatch(setMacAddress(device.mac_address));
              console.log('macAddressPro',macAddress);
              retrieveDeviceData(device.mac_address);
              setShowDeviceDetail(true);
      
           }}>
              <p
                style={{
                  position: "relative",
                  width: "70%",
                  fontSize: "15px",
                  color: "black",
                  display: "flex",
                  textAlign: "center",
                  left: "12%",
                  whiteSpace: "break-spaces",
                }}
              >
                {device.display_name}
              </p>
              <span
                style={{
                  display: "block",

                  fontSize: "13px",
                  color: "#54575c",

                  marginLeft: "19px",
                  transform: "translateY(-70%)",
                }}
              >
                {device.mac_address}
              </span>
              </div>
              <div className="icons">
                {device.deviceSocket === null ? (
                  <span
                    style={{
                      position: "absolute",
                      left: "8%",
                      color: "red",
                      //bottom: "50%",
                      top: "55%",
                      transform: "translate(0,-74%)",
                 
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="8"
                      fill="currentColor"
                      className="bi bi-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <circle cx="8" cy="8" r="8" />
                    </svg>
                  </span>
                ) : (
                  <span
                    style={{
                      position: "absolute",
                      left: "8%",
                      color: "#00F700",
                      //bottom: "50%",
                      top: "55%",
                      transform: "translate(0,-74%)",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="8"
                      fill="currentColor"
                      className="bi bi-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <circle cx="8" cy="8" r="8" />
                    </svg>
                  </span>
                )}
                <span
                 id="iconFill"
                  style={{
                    position: "absolute",
                    right: "8%",
                    //color: "grey",
                    // bottom: "44%",
                    
                    top: "50%",
                    transform: "translate(0,-55%)",
                  }}
                  onClick={() => {
                    handleDeleteDevice(device.mac_address);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-trash"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path
                      fillRule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    />
                  </svg>
                </span>
                <span
                 id="iconFill"
                  style={{
                    position: "absolute",
                    right: "20%",
                    //color: "grey",
                    //bottom: "46%",
                    top: "50%",
                    transform: "translate(0,-55%)",
                  }}
                  onClick={() => {
                    handleClick(device.mac_address, device.display_name);
                    
                    if(device.deviceSocket){ CallDevice(device.mac_address, device.display_name);} 
                    else{Swal.fire({
                     icon: 'error',
                     text:t( 'Please verify device connection'),
                 
                     confirmButtonColor:"#0088FF"
                   })}
                   
                  }}
                >
                  <MdLocalPhone />
                </span>
              </div>
            </MenuItem>
          ))}
          <SubMenu
            style={{
              color: "#FEFEFE",
              backgroundColor: "#0088ff",
              marginTop: "15px",
              width: "90%",
              transform: "translate(5%, -0)",
              zIndex: 0,
            }}
            defaultOpen
            title={t("Invitations Sent")}
            icon={<AiOutlineAppstoreAdd />}
          >
            {sentInvitations.map((friendsData) => (
              <MenuItem
              id="menu-item"
                key={friendsData.id}
                style={{
                  //color: "black",
                  //backgroundColor: "#DDD",
                  // borderRadius: "30px",
                  transform: "translate(-5%,-15px)",
                  marginTop: "10px",
                  zIndex: 0,
                }}
              > 
                <img
                  style={{
                    width: "20px",
                    height: "30px",
                    float: "left ",
                    position: "absolute",
                    /*  top:"30px" */ top: "50%",
                    transform: "translate(0%,-50%)",
                  }}
                  alt="img"
                  align="left"
                  src={friendsData.photo_url || userAvatar}
                />
                <p
                  style={{
                    position: "relative",
                    width: "100%",
                    fontSize: "15px",
                    color: "black",
                    display: "flex",
                    left: "70%",
                    transform: "translate(-50%,0)",
                    whiteSpace: "break-spaces",
                  }}
                >
                  {friendsData.display_name}
                </p>
                <span
                 id="iconFill"  
                  style={{
                    position: "absolute",
                    right: "5%",
                   // color: "grey",
                    top: "50%",
                    transform: "translate(0,-50%)",
                  }}
                  onClick={() => {
                    handleClickFriend(friendsData.email);
                    handleDeleteInvitation(friendsData.email);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-trash"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path
                      fillRule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    />
                  </svg>
                </span>
              </MenuItem>
            ))}
          </SubMenu>
        </Menu>
        <SidebarFooter style={{ textAlign: "center" }}>
          <div className="login-page_second_logo_container">
            <img
              style={{
                position: "absolute",
                width: "180px",
                paddingBottom: "25%",
              }}
              src={Logo_Avempace}
              alt="ViziaLive"
            />
          </div>
        </SidebarFooter>
      </ProSidebar></SidebarOutsiderAlerter>
    </>
  );
};
function mapStoreStateToProps({ call, data }) {
  return {
    ...call,
    ...data,
  };
}
export default connect(mapStoreStateToProps, {
  retrieveMaterialTypes,
  getSentInvitations,
  retrieveDeviceName,
  retrieveDeviceData,
  getMyFriendsList

})(ProSideBarComponent);
