import React, { useState } from "react";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
const EmailInput = (props) => {
  const { email, setEmail } = props;
  const [valid, setValid] = useState(false);
  const [showError, setShowError] = useState(false);
  const onClick = () => setShowError(true);
  const onBlur = () => setShowError(false);

  return (
    <TextField
      margin="normal"
      required
      fullWidth
      id="email"
      label="Email "
      name="email"
      autoComplete="email"
      style={{ marginBottom: "13px" }}
      autoFocus
      onFocus={onClick}
      onBlur={onBlur}
      onChange={(event) => {
        setEmail(event.target.value);
        setValid(
          Yup.string()
            .min(2, "password is Too Short!")
            .max(50, "password is Too Long!")
            .email("Invalid email")
            .isValidSync(event.target.value)
        );
      }}
    />
  );
};
export default EmailInput;
