import "./App.css";
import appConfig from "./config/appConfig.json";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import CallView from "./CallView/CallView";
import GroupCallView from "./CallView/GroupCallView"
import MobileCallView from "./CallView/CallViewMobile";
import SignInPage from "./LoginPage/SignInPage";
import SignUpPage from "./LoginPage/SignUpPage";
import EventPage from "./Event/Event";
import io from "socket.io-client";
import ResponsiveGroupCallView from "./CallView/ResponsiveGroupCallView"
import UserCallView from "./CallView/UserCallView"
const socket = io(appConfig.baseUrl);

function App() {
console.log("isLoggesIn",localStorage.getItem("TOKEN_KEY") != null)
  return (
    <Router>
      <Switch>
  
        {localStorage.getItem("TOKEN_KEY") !== null && <Route exact path="/">
          <Redirect to="/dashboard" />
        </Route>}
     
        {localStorage.getItem("TOKEN_KEY") === null && <Route exact path="/">
          <Redirect to="/signin" />
        </Route>}

        <Route path="/mobileCallView/:roomId" >
      <MobileCallView socket={socket} ></MobileCallView> </Route>

    <Route path="/groupCallView/:roomID" component={GroupCallView} /> 
    <Route path="/userCallView/:roomID" component={UserCallView} /> 
    <Route path="/ResponsiveGroupCallView/:roomID" component={ResponsiveGroupCallView} /> 
        <Route path="/Event">
          <EventPage socket={socket}></EventPage>
        </Route>

    
      
      {localStorage.getItem("TOKEN_KEY") === null ?<Route exact path="/dashboard">
          <Redirect to="/signin" />
        </Route>: <Route path="/dashboard">
          <CallView socket={socket}></CallView>
        </Route>}  

        {localStorage.getItem("TOKEN_KEY") !== null ?<Route exact path="/signin">
          <Redirect to="/dashboard" />
        </Route>: <Route path="/signin">
        <SignInPage/>
        </Route>}  
        
        {localStorage.getItem("TOKEN_KEY") !== null ?<Route exact path="/signup">
          <Redirect to="/dashboard" />
        </Route>: <Route path="/signup">
        <SignUpPage/>
        </Route>} 
        {/* <Route exact path="/Signup">
          <SignUpPage socket={socket} />
        </Route>
     */}

        <Route exact path="/Signin">
          <SignInPage socket={socket} />{" "}
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
